import React from "react";
import { Component } from "react";
import "./StatsArea.css";
import DataGrid from 'react-data-grid';
import 'react-data-grid/lib/styles.css';
import moment from 'moment';
import Papa from 'papaparse';
import { ScatterChart,
         CartesianGrid, 
         XAxis, 
         YAxis, 
         Tooltip, 
         Scatter, 
         ResponsiveContainer, 
         BarChart, 
         Bar,  
         LabelList } from 'recharts';
import transactionType from "../../Functions/Transactiontype";

const taxRates = {
  'Adjuntas': 0.0833,
  'Aguada': 0.0883,
  'Aguadilla': 0.1033,
  'Aguas Buenas': 0.1033,
  'Aibonito': 0.0933,
  'Añasco': 0.0968,
  'Arecibo': 0.1163,
  'Arroyo': 0.1033,
  'Barceloneta': 0.1033,
  'Barranquitas': 0.0933,
  'Bayamón': 0.0958,
  'Cabo Rojo': 0.1008,
  'Caguas': 0.1033,
  'Camuy': 0.1033,
  'Canóvanas': 0.1033,
  'Carolina': 0.1133,
  'Cataño': 0.1033,
  'Cayey': 0.1058,
  'Ceiba': 0.0933,
  'Ciales': 0.1033,
  'Cidra': 0.1233,
  'Coamo': 0.1033,
  'Comerío': 0.0908,
  'Corozal': 0.0983,
  'Culebra': 0.0883,
  'Dorado': 0.1058,
  'Fajardo': 0.1033,
  'Florida': 0.1033,
  'Guánica': 0.1058,
  'Guayama': 0.1008,
  'Guayanilla': 0.1083,
  'Guaynabo': 0.1008,
  'Gurabo': 0.1183,
  'Hatillo': 0.0905,
  'Hormigueros': 0.0983,
  'Humacao': 0.0883,
  'Isabela': 0.0933,
  'Jayuya': 0.0808,
  'Juana Díaz': 0.0933,
  'Juncos': 0.1133,
  'Lajas': 0.1233,
  'Lares': 0.1008,
  'Las Marías': 0.0818,
  'Las Piedras': 0.1033,
  'Loíza': 0.1173,
  'Luquillo': 0.0933,
  'Manatí': 0.0958,
  'Maricao': 0.1033,
  'Maunabo': 0.0983,
  'Mayagüez': 0.1058,
  'Moca': 0.0958,
  'Morovis': 0.1083,
  'Naguabo': 0.0983,
  'Naranjito': 0.0983,
  'Orocovis': 0.0803,
  'Patillas': 0.1033,
  'Peñuelas': 0.0858,
  'Ponce': 0.1233,
  'Quebradillas': 0.0883,
  'Rincón': 0.1083,
  'Río Grande': 0.1033,
  'Sabana Grande': 0.1033,
  'Salinas': 0.1033,
  'San Germán': 0.1008,
  'San Juan': 0.1033,
  'San Lorenzo': 0.1023,
  'San Sebastián': 0.0908,
  'Santa Isabel': 0.1083,
  'Toa Alta': 0.1133,
  'Toa Baja': 0.1183,
  'Trujillo Alto': 0.1058,
  'Utuado': 0.0883,
  'Vega Alta': 0.0868,
  'Vega Baja': 0.1033,
  'Vieques': 0.1183,
  'Villalba': 0.0958,
  'Yabucoa': 0.1083,
  'Yauco': 0.1033
}

class StatsArea extends Component{

    constructor(props){
        super(props);
        this.state={
            statsShow:false,
            columns:[
                { key: 'SALEPRICE', name: 'Precio de Venta', sortable: true, width:140 },
                { key: 'INTRINSICVAL', name: 'Valor Intrínseco', sortable: true, width:140 },
                { key: 'EQUITY', name: 'Equidad', sortable: true, width:140 },
                { key: 'SALESDATE', name: 'Fecha de Venta', sortable: true, width:120 },
                { key: 'STRUCTURE', name: 'Estructura (FT²)', sortable: true, width:250 },
                { key: 'TERRAINSIZE', name: 'Tamaño de terreno (M²)', width:250 },
                { key: 'PRICESQFT', name: 'Precio  / FT²', sortable: true, width:110},
                { key: 'ADDRESS', name: 'Physical Address', minWidth:300},
                { key: 'BUYER', name: 'Buyer', minWidth:250},
                { key: 'SELLER', name: 'Seller Name', minWidth:250 },
                { key: 'TAXABLE', name: 'Mensualidad CRIM', minWidth:250 },
            ],
            indexedStatDisplay:'spreadsheet'
        }
        this.onGridSort = this.onGridSort.bind(this);
    }

    statsShowHandle(){
        if(this.state.statsShow===true){this.setState({statsShow:false})}
        else{this.setState({statsShow:true})}
    }

    componentDidMount(){
      if(this.props.selectedLanguage==='english'){
        this.setState({
          columns:[
            { key: 'SALEPRICE', name: 'Sales Price', sortable: true, width:140 },
            { key: 'INTRINSICVAL', name: 'Intrinsic Value', sortable: true, width:140 },
            { key: 'EQUITY', name: 'Equity', sortable: true, width:140 },
            { key: 'SALESDATE', name: 'Sales Date', sortable: true, width:120 },
            { key: 'STRUCTURE', name: 'Structure (FT²)', sortable: true, width:250 },
            { key: 'TERRAINSIZE', name: 'Land Size (M²)', width:250 },
            { key: 'PRICESQFT', name: 'Price  / FT²', sortable: true, width:110},
            { key: 'ADDRESS', name: 'Physical Address', minWidth:300},
            { key: 'BUYER', name: 'Buyer', minWidth:250},
            { key: 'SELLER', name: 'Seller Name', minWidth:250 },
            { key: 'TAXABLE', name: 'Property Tax (Monthly)', minWidth:250 },
          ]
        })
      }
    }

    languageIndexer(spanishMessage,englishMessage){
      if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
      else if(this.props.selectedLanguage==='english'){return(englishMessage);}
    }


    renderScatterPlot() {
        const processedData = this.props.dataRegister.map(entry =>{
          try{
            if(transactionType(entry.attributes)==="VENTA"){
              return({
                SALESAMT: entry.attributes.SALESAMT,
                SALESDATE_RAW: Math.abs(entry.attributes.SALESDTTM), // This is the raw value for sorting
                SALESDATE: moment(Math.abs(entry.attributes.SALESDTTM)).format('MM-DD-YYYY'), // This is for display
                DIRECCION_FISICA: entry.attributes.DIRECCION_FISICA,
                CONTACT: entry.attributes.CONTACT,
                SELLERNAME: entry.attributes.SELLERNAME,
                MUNICIPIO: entry.attributes.MUNICIPIO,
                ESTRUCTURA: entry.attributes.STRUCTURE
              });
            }
            else{
              return("")
            }
          }catch{return("")}
        })
    
        const CustomizedAxisTick = (props) => {
          const { x, y, payload } = props;
          return (
            <g transform={`translate(${x},${y})`}>
              <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                {moment(Math.abs(payload.value)).format('MM-DD-YYYY')}
              </text>
            </g>
          );
        };

        const processDireccionFisica=(indata)=>{
          try{return (indata.DIRECCION_FISICA.split(" PR ")[0]);}
          catch{return (indata.DIRECCION_FISICA);}
        }

        const CustomTooltip = ({ active, payload }) => {
          
            if (active && payload && payload.length) {
              const data = payload[0].payload;
              return (
                <div className="popupToolTipScatter">
                  <div><strong>Date:</strong>{data.SALESDATE}</div>
                  <div><strong>Sales Amount:</strong>${data.SALESAMT}</div>
                  <div><strong>{this.languageIndexer("Municipio:",'Municipality:')}</strong>{data.MUNICIPIO}</div>
                  <div><strong>Address:</strong>{processDireccionFisica(data)}</div>
                  <div><strong>{this.languageIndexer("Estructura:",'Structure:')}</strong>{data.ESTRUCTURA/10}SQFT</div>
                  <div><strong>Buyer:</strong>{data.CONTACT}</div>
                  <div><strong>Seller:</strong>{data.SELLERNAME}</div>
                </div>
              );
            }
          
            return null;
          };

          const minEstructura = Math.max(...processedData.map(data =>{
            if(typeof data.ESTRUCTURA !== 'undefined' && !isNaN(Number(data.ESTRUCTURA)) && Number.isNaN(data.ESTRUCTURA) === false){
              return(Number(data.ESTRUCTURA))
            }
            return ""
          }).filter(element => element !== undefined))
          const maxEstructura = Math.min(...processedData.map(data =>{
            if(typeof data.ESTRUCTURA !== 'undefined' && !isNaN(Number(data.ESTRUCTURA)) && Number.isNaN(data.ESTRUCTURA) === false){
              return(Number(data.ESTRUCTURA))
            }
            return ""
          }).filter(element => element !== undefined))
          const CustomDot = (props) => {
            const { cx, cy, payload } = props;

            // Normalize the data.ESTRUCTURA value to a value between 0 and 1
            const normalizedValue = (payload.ESTRUCTURA - minEstructura) / (maxEstructura - minEstructura);

            // Interpolate between red and blue
            const red = Math.floor(255 * (1 - normalizedValue));
            const fillColor = `rgb(255, ${255-red}, 0)`;

            const radius = window.innerWidth < 600 ? 8 : 6;

            return <circle cx={cx} cy={cy} r={radius} fill={fillColor} />;
          };

          const GradientLegend = ({ min, max }) => {
            return (
              <section>
                <div><strong>
                  {this.languageIndexer("Tamaño de Estructura",'Structure Size')}
                </strong></div>
                <div className="gradientLegendContainer">
                  <div>{max}<strong>&nbsp;SQ-FT&nbsp;</strong></div>
                  <div className="gradientLegend" style={{ 
                    background: `linear-gradient(90deg, rgb(255, 255, 0), rgb(255, 0, 0))` 
                  }}></div>
                  <div>{min}<strong>&nbsp;SQ-FT</strong></div>
                </div>
              </section>
            );
          };

        const minDate = Math.min(...processedData.map(data => data.SALESDATE_RAW));
        const maxDate = Math.max(...processedData.map(data => data.SALESDATE_RAW));
        const minPrice = Math.min(...processedData.map(data => data.SALESAMT));
        const maxPrice = Math.max(...processedData.map(data => data.SALESAMT));
        return (
          <div className="scatterContainer" tyle={{ textAlign: 'center', marginTop: '50px' }}>
            <div className="scatterInner">
            <h2>{this.languageIndexer("Ventas en el tiempo",'Sales In Time')}</h2>
              <ResponsiveContainer className="scatterLimit">
                <ScatterChart
                  width={600}
                  height={400}
                  margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                >
                  <CartesianGrid />
                  <XAxis 
                    type="number" 
                    dataKey="SALESDATE_RAW" 
                    name="Date" 
                    tick={<CustomizedAxisTick />} 
                    domain={[minDate, maxDate]}
                  />
                  <YAxis type="number" dataKey="SALESAMT" name="Sales Amount" unit="$" domain={[minPrice,maxPrice]}/>
                  <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />}/>
                  <Scatter name="Sales" data={processedData} fill="#8884d8" shape={CustomDot}/>
                </ScatterChart>
              </ResponsiveContainer>
              <GradientLegend min={minEstructura/10} max={maxEstructura/10} />
            </div>
          </div>
        );
      }

      processDireccionFisica=(indata)=>{
        try{return (indata.split(" PR ")[0]);}
        catch{return (indata);}
      }


    renderTable(){
        if(this.state.indexedStatDisplay==="spreadsheet"){
            let maxSalesDTTMIndex = 0;
            let secondMaxSalesDTTMIndex = 0;
            let maxSalesDTTM = Number.NEGATIVE_INFINITY;
            let secondMaxSalesDTTM = Number.NEGATIVE_INFINITY;

            for (let i = 0; i < this.props.dataRegister.length; i++) {
                const currentSalesDTTM = this.props.dataRegister[i].attributes.SALESDTTM;
                if (currentSalesDTTM > maxSalesDTTM) {
                    // Move current max to second max
                    secondMaxSalesDTTM = maxSalesDTTM;
                    secondMaxSalesDTTMIndex = maxSalesDTTMIndex;

                    // Update max
                    maxSalesDTTM = currentSalesDTTM;
                    maxSalesDTTMIndex = i;
                } else if (currentSalesDTTM > secondMaxSalesDTTM && currentSalesDTTM !== maxSalesDTTM) {
                    secondMaxSalesDTTM = currentSalesDTTM;
                    secondMaxSalesDTTMIndex = i;
                }
            }
            let lastElement = this.props.dataRegister[maxSalesDTTMIndex];
            if(transactionType(lastElement.attributes)!=='VENTA'){
              lastElement = this.props.dataRegister[secondMaxSalesDTTMIndex];
            }
            let avgPriceSQFT=lastElement['attributes']['SALESAMT']/(lastElement['attributes']['STRUCTURE']/10)

            let rows = this.props.dataRegister.map(entry => ({
                SALEPRICE: entry.attributes.SALESAMT,
                SALESDATE_RAW: Math.abs(entry.attributes.SALESDTTM), // This is the raw value for sorting
                SALESDATE: moment(Math.abs(entry.attributes.SALESDTTM)).format('MM-DD-YYYY'), // This is for display
                ADDRESS: this.processDireccionFisica(entry.attributes.DIRECCION_FISICA),
                BUYER: entry.attributes.CONTACT,
                SELLER: entry.attributes.SELLERNAME,
                STRUCTURE: entry.attributes.STRUCTURE/10,
                TERRAINSIZE: entry.attributes.CABIDA,
                TAXABLE:((((entry.attributes.TAXABLE*taxRates[entry.attributes.MUNICIPIO])/12))*0.9).toFixed(2),
                PRICESQFT: Math.floor(entry.attributes.SALESAMT / (entry.attributes.STRUCTURE/10)),
                INTRINSICVAL: Math.floor((entry.attributes.STRUCTURE/10)*avgPriceSQFT),
                EQUITY:Math.floor((Math.floor((entry.attributes.STRUCTURE/10)*avgPriceSQFT))-entry.attributes.SALESAMT)
            }));
        
            // Sorting logic
            const { sortColumn, sortDirection } = this.state;
            const comparer = (a, b) => {
                const { sortColumn, sortDirection } = this.state;
                
                if (sortDirection === "ASC") {
                    return a[sortColumn + (sortColumn === 'SALESDATE' ? '_RAW' : '')] > 
                        b[sortColumn + (sortColumn === 'SALESDATE' ? '_RAW' : '')] ? 1 : -1;
                } else if (sortDirection === "DESC") {
                    return a[sortColumn + (sortColumn === 'SALESDATE' ? '_RAW' : '')] < 
                        b[sortColumn + (sortColumn === 'SALESDATE' ? '_RAW' : '')] ? 1 : -1;
                }
                return 0;
            };

            if (sortDirection && sortColumn) {
                rows = rows.sort(comparer);
            }
            
            const exportToCSV = () => {
              const csv = Papa.unparse(rows);
              const blob = new Blob([csv], { type: 'text/csv' });
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);
              link.download = 'data.csv';
              link.click();
            };
          

            function DataGridToolbar({ onExportCSV, onExportHTML }) {
              return (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div className="exportBtnContainer">
                    <button onClick={onExportCSV}>Exportar / Download</button>
                  </div>
                </div>
              );
            }
            

            return (
              <div className="dataGridContainer">
                <DataGridToolbar onExportCSV={exportToCSV}/>
                <DataGrid 
                    className="tableName" 
                    columns={this.state.columns} 
                    rows={rows}
                    onSortColumnsChange={this.onGridSort}
                    style={{overflow:'show',height:"84%",minHeight:"300px"}}
                />
              </div>
            );}
            else if(this.state.indexedStatDisplay==='regression'){
                return(<div className="scatterContainer">{this.renderScatterPlot()}</div>)
            }
            else if(this.state.indexedStatDisplay==='median'){
                return(<div className="barChartContainer">
                  <div className="areaDescription">
                  {this.languageIndexer("Precio promedio por año",'Average Price Per Year')}
                  </div>
                  {this.renderBarChart()}
                  </div>)
            }
            else if(this.state.indexedStatDisplay==='price-sqft'){
                return(<div className="priceSqftContainer">{this.renderPriceSqft()}</div>)
            }
    }

    renderPriceSqft() {
      function isValidEntry(entry) {
        // Check if entry itself is not null
        if (!entry) return false;
      
        // Check if SALESAMT is not null and not NaN
        if (entry.SALESAMT == null || isNaN(entry.SALESAMT)) return false;
      
        // Check if SALESDTTM is not null. Additional checks might be needed based on its format
        if (!entry.SALESDTTM) return false;
      
        // Check if ESTRUCTURA is not null and not NaN
        if (entry.ESTRUCTURA == null || isNaN(entry.ESTRUCTURA)) return false;
      
        return true;
      }
      
      // Use the function
      const processedData = this.props.dataRegister.map(entry => {
        try {
            if (transactionType(entry.attributes) === "VENTA" && entry.attributes.STRUCTURE!==0) {
                const dataEntry = {
                    SALESAMT: entry.attributes.SALESAMT,
                    SALESDTTM: moment(Math.abs(entry.attributes.SALESDTTM)).format('YYYY-MM'), 
                    ESTRUCTURA: entry.attributes.STRUCTURE/10
                };
                return isValidEntry(dataEntry) ? dataEntry : null;
            } else { return null }
        } catch { return null }
      }).filter(Boolean);
      
  
      // Compute the average SALESAMT/ESTRUCTURA for each month-year
      const aggregatedData = {};
  
      processedData.forEach(entry => {
          if (!aggregatedData[entry.SALESDTTM]) {
              aggregatedData[entry.SALESDTTM] = {
                  total: 0,
                  count: 0,
                  avg: 0
              };
          }
          aggregatedData[entry.SALESDTTM].total += (entry.SALESAMT / entry.ESTRUCTURA);
          aggregatedData[entry.SALESDTTM].count++;
      });

  
      const chartData = Object.keys(aggregatedData).map(monthYear => ({
          monthYear: monthYear,
          avg: aggregatedData[monthYear].total / aggregatedData[monthYear].count
      })).sort((a, b) => moment(a.monthYear, "YYYY-MM").valueOf() - moment(b.monthYear, "YYYY-MM").valueOf()); // Sort by month-year
  
      return (
        <div className="areaContainer" style={{ width: '100%', height: '400px' }}> {/* Ensure the parent container has a defined height */}
            <div className="areaDescription">
              {this.languageIndexer("Precio por pie cuadrado de estructuras",'Price per square feet of structures')}
            </div>
            <ResponsiveContainer width="100%" height="60%">
                <BarChart
                    data={chartData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="monthYear" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="avg" fill="#FFA500" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    
      );
  }

    renderBarChart() {
      const processedData = this.props.dataRegister.map(entry => {
        try{
          if(transactionType(entry.attributes)==="VENTA"){
            return ({
              SALESAMT: entry.attributes.SALESAMT,
              SALESDTTM: moment(Math.abs(entry.attributes.SALESDTTM)).format('YYYY'),
            });
          }else{return null}
        }catch{return null}
      }).filter(Boolean); // filter out any null or undefined values from processedData
    
      // Grouping data by year and calculating mean
      const groupedData = processedData.reduce((acc, curr) => {
          if (!acc[curr.SALESDTTM]) {
              acc[curr.SALESDTTM] = { year: curr.SALESDTTM, total: curr.SALESAMT, count: 1 };
          } else {
              acc[curr.SALESDTTM].total += curr.SALESAMT;
              acc[curr.SALESDTTM].count += 1;
          }
          return acc;
      }, {});
    
      const meanData = Object.values(groupedData)
      .map(entry => ({
          year: entry.year,
          mean: entry.total / entry.count
      }))
      .filter(entry => !isNaN(entry.mean) && entry.year !== 'NaN'); // Filtering out invalid data
    
      
      return (
        <ResponsiveContainer className="barChartContainerInner" width="80%" height="80%">
          <BarChart width={600} height={300} data={meanData}>
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="mean" fill="#8884d8">
                  <LabelList 
                  dataKey="mean" 
                  position="middle" 
                  formatter={val => Math.round(val)} 
                  fontSize={10} 
                  angle={-90}
                  fill="black"
                  fontWeight={700}
                  />
              </Bar>
          </BarChart>
        </ResponsiveContainer>
      );
  }
  
    
    onGridSort = (sortColumn) => {
        if(this.state.sortDirection==='ASC')
            {this.setState({ sortColumn:sortColumn[0].columnKey, sortDirection:'DESC' });}
        else
            {this.setState({ sortColumn:sortColumn[0].columnKey, sortDirection:'ASC' });}
    };

    renderStatsShow(){
        if(this.state.statsShow===true){
            return(
                <section className="statsArea">
                    <div className="statsInner">
                        <div className="statsHeader">
                            <div className="statisticDescription">Resultados Estadisticos / Statistic Results</div>
                            <div onClick={()=>this.statsShowHandle()} className="minimizeBtn">Minimize</div>
                        </div>
                        <section className="statButtonArea">
                            <button onClick={()=>{this.setState({indexedStatDisplay:'spreadsheet'})}} className="statButton">SpreadSheet</button>
                            <button onClick={()=>{this.setState({indexedStatDisplay:'regression'})}} className="statButton">Regression</button>
                            <button onClick={()=>{this.setState({indexedStatDisplay:'median'})}} className="statButton">Average Price</button>
                            <button onClick={()=>{this.setState({indexedStatDisplay:'price-sqft'})}} className="statButton">Price/SQFT AVG</button>
                        </section>
                        {this.renderTable()}
                    </div>
                </section>
            );
        }
        else{
            return(<div onClick={()=>this.statsShowHandle()} className="statsShowMinimized">Abrir Estadisticas / Open Statistics</div>);
        }
    }

    
    render(){
        return(this.renderStatsShow())
    }
}

export default StatsArea;


import serverIp from "./serverConfig";
let url = `${serverIp}/api/referal`

const sendReferalComplete = (fingerprint) =>{
    const params = new URLSearchParams(window.location.search);
    const ref = params.get('ref');
    if(ref){
        try{
            fetch(url,{
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors", // no-cors, *cors, same-origin
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                  'Content-Type': 'application/json',
                  'Cache-Control':'no-cache',
                  'Referrer':`${serverIp}/`,
                  // Include the token in the Authorization header
                },
                redirect: "follow", // manual, *follow, error
                // referrerPolicy:"no-referrer-when-downgrade",
                body: JSON.stringify({
                    "referalId":ref,
                    "fingerPrint":fingerprint
                 }), // body data type must match "Content-Type"
              })
        }catch{}
    }
}

export default sendReferalComplete;
import {
  PayPalScriptProvider,
  PayPalButtons,
} from "@paypal/react-paypal-js";
import sendAnalytics from "../../Functions/analytics";
import serverIp from '../../Functions/serverConfig';
import paymentConfig from '../../Functions/paymentVariables';

const handlePaymentSuccess = (paydata, actions, props) => {
  // Get the token from either localStorage or sessionStorage
  let token = localStorage.getItem('token') || sessionStorage.getItem('token');
  if (!token) {
    token = ''; // Handle the case where no token is found
  }

  // Send payment details to backend securely using HTTPS
  fetch(`${serverIp}/api/paypalSubscribed`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      orderId: paydata.orderID,
      paymentId: paydata.paymentID,
      // Include other payment-related data as needed
    }),
  })
    .then(res => res.json())
    .then(data=>{
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('token');
      localStorage.removeItem('token');
      localStorage.removeItem('token');
      localStorage.removeItem('token');
      localStorage.removeItem('token');
      sessionStorage.setItem('token', data.newToken);
      if (sessionStorage.getItem('token') === null) {
        sessionStorage.setItem('token', data.newToken);
        }
        window.location.reload();
      })
};

const PaymentButtonWrapper = (props) => {
  // const amount = paymentConfig.plusMaxBiDailySubscriptionID; // Replace with your actual payment amount
  // const currency = 'USD'; // Replace with your desired currency
  return (
    <PayPalButtons
      key={props.indexedAmmount}
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: parseFloat((props.indexedAmmount + parseFloat((props.indexedAmmount * 0.115).toFixed(2))).toFixed(2)),
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: props.indexedAmmount,
                  },
                  tax_total: {
                    currency_code: 'USD',
                    value: parseFloat(parseFloat((props.indexedAmmount*0.115).toFixed(2)).toFixed(2)),
                  },
                },
              },
            },
          ],
        });
      }}
      onClick={()=>sendAnalytics("plusmaxpayform","Paypal payment attempted.",String(new Date()))}
      onApprove={(data,actions)=>{
        handlePaymentSuccess(data)
        window.fbq('track', 'Purchase', {value: props.indexedAmmount, currency: 'USD'});
      }}
      style={{
        label: "pay"
      }}
    />
  );
};

export default function PaypalBtnSP(props) {
  return (
    <>
      <PayPalScriptProvider
        indexedAmmount={props.indexedAmmount}
        options={{
          "client-id": paymentConfig.paypalClientId,
          components: "buttons",
          intent: "capture",
          vault: true, // Disable vaulting for one-time payments
          disableFunding:"paylater,card"
        }}
      >
        <PaymentButtonWrapper indexedAmmount={props.indexedAmmount} />
      </PayPalScriptProvider>
    </>
  );
}
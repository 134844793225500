import React,{Component} from 'react';
import './cardForm.css';
import serverIp from '../../Functions/serverConfig';
import {
  CardElement,
  ElementsConsumer,
  Elements
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaypalBtn from './paypalButtonPlusMax';
import logo from '../../images/stripe-payment-logo.png';
import paymentConfig from '../../Functions/paymentVariables';
import sendAnalytics from '../../Functions/analytics';
import PaypalBtnSP from './PlusMaxSinglePaymentPaypal';
import megaphone from './icons8-announcement-48.png';
import jwt_decode from 'jwt-decode';

// Load your Stripe publishable key
const stripePromise = loadStripe(paymentConfig.stripePublishableKey);

const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#303238',
        fontSize: '16px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#8395a7',
        },
      },
      invalid: {
        color: '#e5424d',
        ':focus': {
          color: '#303238',
        },
      },
    },
  };
  

class CheckoutForm extends Component {
  
  constructor(props){
    super(props);
    this.state={
      transactionMessage:'',
      subscriptionPrice:paymentConfig.plusMaxDaily,
      transactionState:"Done",
      paymentMode:'oneoff',
      subscriptionPlanId:paymentConfig.plusMaxDaily,
      indexedSubscription:paymentConfig.plusMaxDaily,
      indexedPaypalSubscriptionID:'',
      showComparteYGana:false,
      decodeToken:''
    }
  }

  handleSubmit = async (event) => {
    sendAnalytics("plusmaxpayform","Stripe payment attempted.",String(new Date()))
    window.fbq('track', 'Purchase', {value: this.state.subscriptionPrice, currency: 'USD'});
    this.setState({transactionState:"in-process"})
    event.preventDefault();
    const { stripe, elements } = this.props;
  
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
  
    let token = localStorage.getItem('token');
    if (!token) {
      token = sessionStorage.getItem('token');
    }
    if (!token) {
      token = '';
    }
  
    const cardElement = elements.getElement(CardElement);
  
    try {
      // Create the subscription
      const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
  
      if (!paymentMethod) {
        this.setState({transactionState:"Done"})
        throw new Error('Payment method could not be created.');
      }
  
      // Create the subscription using the payment method
      const response = await fetch(`${serverIp}/api/processCardInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          subscriptionPlanId: this.state.indexedSubscription,
          'chosenLevel':'plusMax',
          'paymentMode':this.state.paymentMode
          // Include other subscription-related data as needed
        }),
      });
  
      if (!response.ok) {
        this.setState({transactionState:"Done"})
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
  
      sessionStorage.removeItem('token');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('token');
        localStorage.removeItem('token');
        localStorage.removeItem('token');
        localStorage.removeItem('token');
        localStorage.removeItem('token');
        sessionStorage.setItem('token', data.newToken);
        if (sessionStorage.getItem('token') === null) {
          sessionStorage.setItem('token', data.newToken);
          }
        window.location.reload();
        this.setState({transactionState:"Done"})
  
      window.location.reload();
    } catch (error) {
      this.setState({ transactionMessage: error.message });
    }
  };

  componentDidMount(){
    try{
    document.querySelector("#root > div > div > section.accountPage > section > section > div > div:nth-child(2) > form > button").style.marginTop="12px"
    document.querySelector("#root > div > div > section.accountPage > section > section > div > div:nth-child(2) > form > button").style.marginBottom="-12px"}
    catch{}
    window.gtag('event','page_view',{'page_location':'Plus Max Card Form'})
    sendAnalytics("plusmaxpayform","Free or plus user reached the plus pay form.",String(new Date()))
    let token = localStorage.getItem('token');
    if (!token) {
      token = sessionStorage.getItem('token');
    }
    if (!token) {
      token = '';
    }
    this.decodeToken()
  }

  decodeToken(){
    // Retrieve the token from localStorage

    let token;

    token = localStorage.getItem('token');
    
    if(!token){
      token = sessionStorage.getItem('token');
    }

    if (token) {
      // Decode the token
      const decodedToken = jwt_decode(token);

      // Update state with the token and its expiration status
      this.setState({ decodeToken:decodedToken });
    }
  }

  handlePayBtnShow(){

    if(this.state.transactionState==="in-process"){
      return(<div style={{paddingTop:10,fontSize:14,fontWeight:700}}>Processing...please wait.</div>)
    }
    else{
      return(
        <button type="submit" disabled={!this.props.stripe} className="pay-with-stripe-btn">
            Secure Payment With Stripe
        </button>
      )
    }
  }

  paypalPaymentIndexer(){
    if(this.state.paymentMode==='recurrent'){
      return(<PaypalBtn 
        paypalSubscriptionID={this.state.indexedPaypalSubscriptionID}
        indexedAmmount={this.state.subscriptionPrice}
        />)
    }
    else{
      return(<PaypalBtnSP indexedAmmount={this.state.subscriptionPrice}/>)
    }
  }

  comparteYGana(){
    if(this.state.showComparteYGana===false){
      return(
        <div 
          onClick={()=>this.setState({showComparteYGana:true})}
          style={{
            background:'#FCBB32',
            color:'rgba(0,0,0,.7)',
            fontWeight:700,
            width:320,
            height:40,
            marginLeft:'auto',
            marginRight:'auto',
            display:'flex',
            alignItems:'center',
            justifyContent:'center',
            cursor:'pointer',
            borderRadius:'5px'
            }}>
            Try PlusMAX by taking part in a Share and Win Program!
            <img src={megaphone} style={{height:30,width:'auto'}} alt=''/>
          </div>
      );
    }
    else{
      return(
        <div className='comparteYGana'>
            <div>
              Copy and share the following link! For every 5 people who subscribe to our platform using your link, we will grant you one free PlusMAX search, up to a maximum of 2 searches. Invite others and enjoy more from <strong>Casadata!</strong>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
              <div
                style={{
                  position: 'relative',
                  width: '280px',
                  border: '1px solid #ccc',
                  borderRadius: '5px',
                  padding: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#f9f9f9',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                }}
              >
                <input
                  type="text"
                  value={`https://casadata.io/?ref=${this.state.decodeToken.id}`}
                  readOnly
                  style={{
                    flex: 1,
                    border: 'none',
                    backgroundColor: 'transparent',
                    fontSize: '14px',
                    color: '#333',
                    padding: '0 10px',
                    outline: 'none',
                    whiteSpace: 'nowrap', // Prevent the text from wrapping
                    overflow: 'hidden', // Hide the overflow content
                    textOverflow: 'ellipsis', // Show ellipsis for overflowed text
                    cursor: 'text', // Indicate that the user can select text
                  }}
                />
                {/* <button
                  onClick={this.copyToClipboard}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    padding: '6px 12px',
                    borderRadius: '3px',
                    cursor: 'pointer',
                    fontSize: '12px',
                  }}
                >
                  Copiar
                </button> */}
              </div>
              {this.state.copySuccess && <p style={{ marginLeft: '10px', color: '#4CAF50' }}>{this.state.copySuccess}</p>}
            </div>
        </div>
      );
    }
  }

  render() {
    return (
      <form className='payForm' onSubmit={this.handleSubmit}>
          <div className='memberShipSelectBtnContainer'>
            <div className='memberShipSelectBtn' onClick={()=>this.setState({subscriptionPrice:paymentConfig.plusMaxDaily,paymentMode:'oneoff',indexedSubscription:paymentConfig.plusMaxDaily})}>
              <div>Select</div>
              <div style={{fontSize:15,display:'flex'}}><div className='priceNo1'>${paymentConfig.plusMaxDaily}</div><div style={{fontSize:8,marginTop:3,marginLeft:2}}>+TAX</div></div><div style={{marginTop:-6}}>/2 days</div>
              <div style={{background:'gray',color:'white',width:'100%',height:38,display:'flex',justifyContent:'center',alignItems:'center'}}>${(paymentConfig.plusMaxDaily/2).toFixed(2)}/day</div>
            </div>
            <div className='memberShipSelectBtn' onClick={()=>this.setState({subscriptionPrice:paymentConfig.plusMaxWeekly,paymentMode:'oneoff',indexedSubscription:paymentConfig.plusMaxWeekly})} style={{borderRadius:'0 0 0 0'}}>
              <div style={{marginBottom:-8,color:'rgba(0,0,0,.8)'}}>Select</div>
              <div style={{fontSize:15,display:'flex'}}><div className='priceNo'>${paymentConfig.plusMaxWeekly}</div><div style={{fontSize:8,marginTop:14,marginLeft:2}}>+TAX</div></div><div>/7 days</div>
              <div style={{background:'gray',color:'white',height:38}}>${(paymentConfig.plusMaxWeekly/7).toFixed(2)}/day, <strong>Save {
              (
                (
                  100-((paymentConfig.plusMaxWeekly/7)/(paymentConfig.plusMaxDaily/2))*100)
                  ).toFixed(0)
              }%</strong></div>
            </div>
            <div className='memberShipSelectBtn' style={{borderRadius:'0 0 0 0'}} onClick={()=>this.setState({subscriptionPrice:paymentConfig.plusMaxMonthly,paymentMode:'recurrent',indexedSubscription:paymentConfig.stripePlusMaxSubscriptionPlanIDMonthly,indexedPaypalSubscriptionID:paymentConfig.paypalPlusMaxPlanIdMonthly})}>
              <div style={{marginBottom:-8,color:'rgba(0,0,0,.8)'}}>Select</div>
              <div style={{fontSize:15,display:'flex'}}><div className='priceNo'>${paymentConfig.plusMaxMonthly}</div><div style={{fontSize:8,marginTop:14,marginLeft:2}}>+TAX</div></div><div>Monthly</div>
              <div style={{background:'gray',color:'white',height:38}}>${(paymentConfig.plusMaxMonthly/30.5).toFixed(2)}/day, <strong>Save {
              (
                (
                  100-((paymentConfig.plusMaxMonthly/30.5)/(paymentConfig.plusMaxDaily/2))*100)
                  ).toFixed(0)
              }%</strong></div>
            </div>
            <div className='memberShipSelectBtn' style={{borderRadius:'0 10px 10px 0'}} onClick={()=>this.setState({subscriptionPrice:paymentConfig.plusMaxYearly,paymentMode:'recurrent',indexedSubscription:paymentConfig.stripePlusMaxSubscriptionPlanIDYearly,indexedPaypalSubscriptionID:paymentConfig.paypalPlusMaxPlanIdYearly})}>
              <div style={{marginBottom:-8,color:'rgba(0,0,0,.8)'}}>Select</div>
              <div style={{fontSize:15,display:'flex'}}><div className='priceNo'>${paymentConfig.plusMaxYearly}</div><div style={{fontSize:8,marginTop:14,marginLeft:2}}>+TAX</div></div><div>Annualy</div>
              <div style={{background:'gray',color:'white',height:38}}>${(paymentConfig.plusMaxYearly/366).toFixed(2)}/day, <strong>Save {
              (
                (
                  100-((paymentConfig.plusMaxYearly/366)/(paymentConfig.plusMaxDaily/2))*100)
                  ).toFixed(0)
              }%</strong></div>
            </div>
          </div>
          <div className='breakDownBox' style={{display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
            <div><img alt="" style={{width:80,height:80}} src={logo}/></div>
            <div>
              <div className='pricingBar'>
                <div className='pricingBarTab' style={{fontWeight:700,paddingLeft:10}}>Access</div>
                <div className='subscriptionPrice'>${this.state.subscriptionPrice}</div>
              </div>
              <div className='pricingBar'>
                <div className='pricingBarTab' style={{fontWeight:700,paddingLeft:20,width:35}}>TAX</div>
                <div className='subscriptionPrice'>${(this.state.subscriptionPrice*0.115).toFixed(2)}</div>
              </div>
              <div className='pricingBar'>
                <div className='pricingBarTab' style={{fontWeight:700,paddingLeft:15,width:40}}>Total</div>
                <div className='subscriptionPrice'>${((this.state.subscriptionPrice*0.115)+this.state.subscriptionPrice).toFixed(2)}</div>
              </div>
            </div>
          </div>
          <CardElement options={CARD_ELEMENT_OPTIONS}/>
          {this.handlePayBtnShow()}
          <div className='errorMessageCard'>{this.state.transactionMessage}</div>
          {this.paypalPaymentIndexer()}
          <div style={{color:'rgba(0,0,0,.5)'}}>Cancel at any time.</div>
          {this.comparteYGana()}
        </form>
    );
  }
}

const InjectedCheckoutForm = () => (
  <Elements stripe={stripePromise}>
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  </Elements>
);

export default InjectedCheckoutForm;

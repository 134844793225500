  import React from 'react';
  import './App.css';
  import { Component } from 'react';
  import { MapContainer, TileLayer, FeatureGroup, CircleMarker, Tooltip, Marker} from 'react-leaflet';
  import { EditControl } from 'react-leaflet-draw';
  import './mapControls.css';
  import moment from 'moment';
  import './cloudstyle.css';
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import Searchbar from './Components/Searchbar/Searchbar';
  import StatsArea from "./Components/StatsArea/StatsArea";
  import transactionType from './Functions/Transactiontype';
  import L from 'leaflet';
  import exclamationIcon from "./icons/icons8-exclamation-mark-64.png";
  import reficon from "./icons/icons8-refinance-78.png";
  import LoginPage from './Components/LoginPage/LoginPage';
  import SignUp from './Components/SignupPage/SignUp';
  import AccountPage from './Components/AccountPage/AccountPage';
  import jwt_decode from 'jwt-decode';
  import StatsAreaLight from './Components/StatsArea/StatsAreaLight';
  import StatsAreaMid from './Components/StatsArea/StatsAreaMid';
  import serverIp from './Functions/serverConfig';
  import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
  import Tutorial from './Components/TutorialPage/Tutorial';
  import Frontpage from './Components/FrontPage/Frontpage';
  import Promo from './Components/Promotion/Promo';
  import Terms from './Components/Terms/Terms';
  import Privacy from './Components/Privacy/Privacy';
  import Prompt from '../src/Components/TutorialPrompt/Prompt';
  import sendAnalytics from './Functions/analytics';
  import PlusMaxPrompt from './Components/TutorialPrompt/PlusMaxPrompt';
  import { Audio } from 'react-loader-spinner';
  import prflag from './images/icons8-puerto-rico-48.png';
  import usaflag from './images/icons8-usa-flag-48.png';
  import Stats from './Components/Stats/Stats';

  const taxRates = {
    'Adjuntas': 0.0833,
    'Aguada': 0.0883,
    'Aguadilla': 0.1033,
    'Aguas Buenas': 0.1033,
    'Aibonito': 0.0933,
    'Añasco': 0.0968,
    'Arecibo': 0.1163,
    'Arroyo': 0.1033,
    'Barceloneta': 0.1033,
    'Barranquitas': 0.0933,
    'Bayamón': 0.0958,
    'Cabo Rojo': 0.1008,
    'Caguas': 0.1033,
    'Camuy': 0.1033,
    'Canóvanas': 0.1033,
    'Carolina': 0.1133,
    'Cataño': 0.1033,
    'Cayey': 0.1058,
    'Ceiba': 0.0933,
    'Ciales': 0.1033,
    'Cidra': 0.1233,
    'Coamo': 0.1033,
    'Comerío': 0.0908,
    'Corozal': 0.0983,
    'Culebra': 0.0883,
    'Dorado': 0.1058,
    'Fajardo': 0.1033,
    'Florida': 0.1033,
    'Guánica': 0.1058,
    'Guayama': 0.1008,
    'Guayanilla': 0.1083,
    'Guaynabo': 0.1008,
    'Gurabo': 0.1183,
    'Hatillo': 0.0905,
    'Hormigueros': 0.0983,
    'Humacao': 0.0883,
    'Isabela': 0.0933,
    'Jayuya': 0.0808,
    'Juana Díaz': 0.0933,
    'Juncos': 0.1133,
    'Lajas': 0.1233,
    'Lares': 0.1008,
    'Las Marías': 0.0818,
    'Las Piedras': 0.1033,
    'Loíza': 0.1173,
    'Luquillo': 0.0933,
    'Manatí': 0.0958,
    'Maricao': 0.1033,
    'Maunabo': 0.0983,
    'Mayagüez': 0.1058,
    'Moca': 0.0958,
    'Morovis': 0.1083,
    'Naguabo': 0.0983,
    'Naranjito': 0.0983,
    'Orocovis': 0.0803,
    'Patillas': 0.1033,
    'Peñuelas': 0.0858,
    'Ponce': 0.1233,
    'Quebradillas': 0.0883,
    'Rincón': 0.1083,
    'Río Grande': 0.1033,
    'Sabana Grande': 0.1033,
    'Salinas': 0.1033,
    'San Germán': 0.1008,
    'San Juan': 0.1033,
    'San Lorenzo': 0.1023,
    'San Sebastián': 0.0908,
    'Santa Isabel': 0.1083,
    'Toa Alta': 0.1133,
    'Toa Baja': 0.1183,
    'Trujillo Alto': 0.1058,
    'Utuado': 0.0883,
    'Vega Alta': 0.0868,
    'Vega Baja': 0.1033,
    'Vieques': 0.1183,
    'Villalba': 0.0958,
    'Yabucoa': 0.1083,
    'Yauco': 0.1033
}


  class App extends Component{
    constructor(){
      super();
      // Initialize the state variables
      this.state={
        initialCoords:[18.233682, -66.467499], // Initialize the map over Puerto Rico
        initialZoomOut:9,
        maxZoomOutLimit:9,
        zoom:16,
        zoomApropriate:true,
        setCoords:"",
        dataRegister:[],
        filteredDataRegister:[],
        fetchingStatus:"",
        maxPrice:999999999,
        minPrice:0,
        startDate:'',
        endDate: '',
        startDateRepo:'',
        endDateRepo: '',
        startDateToDisplay:'',
        endDateToDisplay:'',
        startDateToDisplayRepo:'',
        endDateToDisplayRepo:'',
        minStruct:0,
        maxStruct:999999999,
        sendBoxLimitsHide:true,
        filterOutliers:true,
        mapDraggable:true,
        errorActive:'inactive',
        pageIndex:'',
        serverIp:serverIp,
        loginBtnContainerShow:'loginBtnContainer',
        loggedIn:false,
        token:'',
        decodeToken:'',
        floodZoneWarning:'',
        remainingCoolDown:'',
        subscriptionIndex:'',
        toggleBg:'toggleInactive',
        repoData:[],
        userFirstLogin:true,
        paidTierAtInit:false,
        selectedPayment:'',
        selectedLanguage:'spanish',
        searchPlaceholder:'Buscar Direccion Aquí',
        fingerprint:'',
        trials:0
        }
      this.mapRef = React.createRef();
      this.handleMouseOver = this.handleMouseOver.bind(this);
      this.processLastVisit()
    }

      processLastVisit(){
        if((((Number(Date.now().toString()))-Number(localStorage.getItem('id')))>84600)&&(localStorage.getItem('id')!==null)){
          localStorage.setItem('id',Date.now().toString())
          sendAnalytics("HOMEPAGE","PAGEVISIT",String(new Date()))
        }
        else if(localStorage.getItem('id')===null){
          localStorage.setItem('id',Date.now().toString())
          sendAnalytics("HOMEPAGE","PAGEVISIT",String(new Date()))
        }
      }

      // Add mousemove event listener on component mount
      componentDidMount(){
        window.addEventListener('mousemove', this.handleClick);
        document.querySelector("#root > div > div > div.leaflet-control-container > div.leaflet-bottom.leaflet-right").style="display:None;"
        this.verifyToken()
        L.drawLocal.draw.toolbar.buttons.polygon="Seleccionar Area de Interes"
        //PAGE,ACTION,DATE
        
        try{this.fetchTutorialPrompt()}
        catch{}
        try{
          this.fetchTrials()
        }
        catch{}
        try{this.decodeToken()}catch{}
      }
      
      userIsLogedIn(){
        this.verifyToken()
      }

   

      decodeToken(){
        // Retrieve the token from localStorage
    
        let token;
    
        token = localStorage.getItem('token');
        
        if(!token){
          token = sessionStorage.getItem('token');
        }
    
        if (token) {
          // Decode the token
          const decodedToken = jwt_decode(token);

          if(this.state.paidTierAtInit===true && decodedToken.delta!=='11A5D7A0C'){
            setTimeout(()=>this.setState({pageIndex:'account',subscriptionIndex:'PlusMAX'}),1000)
          }

          // Check if the token has expired
    
          // Update state with the token and its expiration status
          this.setState({ decodeToken:decodedToken });
        }
        
      }

      // Define method to calculate and return color based on sales amount
      getSalesAmtColor(salesAmt) {
        let maxSalesAmt = () =>{
          let salesPriceRegister = this.state.dataRegister.map(x => {
            return x["attributes"]["SALESAMT"]
          })
          if(Math.max(...salesPriceRegister)<Number(this.state.maxPrice)){return Math.max(...salesPriceRegister)}
          else{return(Number(this.state.maxPrice));}
        }
        // Calculate color intensity for RGB color model.
        let colorIntensity = 255-Math.floor(((salesAmt / maxSalesAmt())) * 255); //invoke the function here too
        // Return the calculated color in RGB format.
        if(salesAmt<(maxSalesAmt()/2)){
          return `rgb(255, ${colorIntensity}, 0)`;
        }
        else{
          return `rgb(${colorIntensity}, 0, 0)`;
        }
      }
    
    componentDidUpdate(){
      this.getMinMaxDateRange()
      this.upDateBlankBoxes()
      try{
        document.querySelector("#root > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-left > div.leaflet-draw.leaflet-control > div:nth-child(2) > div > a.leaflet-draw-edit-remove.leaflet-disabled").title="Borrar Seleccion"
      }catch{}
      this.toggleTools()
      }

    verifyToken() {
      // Retrieve the token from localStorage
      let token = localStorage.getItem('token');
      this.setState({token:token})
      // Check if the token is not found
      if (!token) {
          token = sessionStorage.getItem('token');
          this.setState({token:token})
      }
    
      if(!token){
        this.setState({token:''})
        return;
      }

      this.decodeToken()

      fetch(`${this.state.serverIp}/api/verify-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include the token in the Authorization header
          'Authorization': `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            // console.error('Server responded with status:', res.status);
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');
            // Here, we need to return the response text because we can't read the response stream again
            // after calling .json()
            return res.text().then(text => Promise.reject(text));
          }
          // Return the promise containing the response data, which will be processed in the next .then
          return res.json();
        })
        .then(
          (data) => {
            this.setState({
              loginBtnContainerShow:"loginBtnContainerHide",
              pageIndex:'',
              loggedIn:true,
            })
          })
        .catch((error) => {
          // Log the error from the server or from the fetch operation
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');
            this.setState({loggedIn:false})
        });
    }


    upDateBlankBoxes(){
      try{
        if(document.getElementById("minno").value===""&&this.state.minPrice!==0){
          document.getElementById("minno").value=this.state.minPrice
        }
        else if(document.getElementById("maxno").value===""&&this.state.maxPrice!==999999999){
          document.getElementById("maxno").value=this.state.maxPrice
        }
        else if(document.getElementById("minStruct").value===""&&this.state.minStruct!==0){
          document.getElementById("minStruct").value=this.state.minStruct/10
        }
        else if(document.getElementById("maxStruct").value===""&&this.state.maxStruct!==999999999){
          document.getElementById("maxStruct").value=this.state.maxStruct/10
        }
      }catch{}
    }

    formatDireccion(address){
      try{return address.split(" PR ")[0]}
      catch{return address}
    }

  getMinMaxDateRange() {
      if(this.state.dataRegister.length>0&&this.state.startDate===""){
        let dateRange = this.state.dataRegister.map(data => {
          return (data.attributes.SALESDTTM);
        });
        this.setState({
          startDate: new Date(new Date(moment(Math.abs(Math.min.apply(null,dateRange))).format('YYYY-MM-DD'))).getTime(),
          endDate: new Date(new Date(moment(Math.abs(Math.max.apply(null,dateRange))).format('YYYY-MM-DD'))).getTime(),
          startDateToDisplay: new Date(new Date(moment(Math.abs(Math.min.apply(null,dateRange))).format('YYYY-MM-DD'))),
          endDateToDisplay:new Date(new Date(moment(Math.abs(Math.max.apply(null,dateRange))).format('YYYY-MM-DD')))
        });
      }
  }

  getMinMaxDateRangeRepo() {
    if(this.state.repoData.length>0&&this.state.startDateRepo===""){
      let dateRange = this.state.repoData.map(data => {
        return (data.attributes.SALESDTTM);
      });
      this.setState({
        startDateRepo: new Date(new Date(moment(Math.abs(Math.min.apply(null,dateRange))).format('YYYY-MM-DD'))).getTime(),
        endDateRepo: new Date(new Date(moment(Math.abs(Math.max.apply(null,dateRange))).format('YYYY-MM-DD'))).getTime(),
        startDateToDisplayRepo: new Date(new Date(moment(Math.abs(Math.min.apply(null,dateRange))).format('YYYY-MM-DD'))),
        endDateToDisplayRepo:new Date(new Date(moment(Math.abs(Math.max.apply(null,dateRange))).format('YYYY-MM-DD')))
      });
    }
  }
    
  handleInformaticError(){
    if(this.state.errorActive==='active')
      return(
        <section className='errorPage'>
          <div className='errorPageInner'>
            <div>Hay problemas con la conexion al servidor, trata lo siguiente para posibles soluciones:</div>
            <div>-Verifica tu conexion al internet.</div>
            <div>-Reinicia tu dispositivo.</div>
            <div>-Trate de conectarse mas tarde.</div>
            <button onClick={()=>this.setState({errorActive:''})}>Volver</button>
          </div>
        </section>
      )
  }

      // Define the handleClick function.
  handleClick = () => {
    
    // Retrieve the style attribute from a specific element in the DOM.
    // The number is extracted from the "scale" style property and converted to a number.
    this.targetNo = Number(
      document
        .querySelector(
          "#root > div > div > div.leaflet-pane.leaflet-map-pane > div.leaflet-proxy.leaflet-zoom-animated"
        )
        .getAttribute("style")
        .split(" ")[4]
        .replace("scale", "")
        .replace("(", "")
        .replace(")", "")
        .replace(";", "")
    );
    // Check if the target number is greater than or equal to 16384.
    if (this.targetNo >= 32768) {
      // If true, set the zoomApropriate state to true.
      this.setState({ zoomApropriate: true });
    } else {
      // If false, set the zoomApropriate state to false.
      this.setState({ zoomApropriate: true});
    }

    // Try to change the display style of another specific element in the DOM to "None".
    try {
      document
        .querySelector(
          "#root > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-left > div.leaflet-draw.leaflet-control > div:nth-child(2) > div > a.leaflet-draw-edit-edit.leaflet-disabled"
        )
        .style["display"] = "None";
    } 
    // If the specific element does not exist or any other error occurs, catch the error to prevent the program from crashing.
    catch {}
    };


      _onCreate = (e) => {
        this.decodeToken()
        const { layerType, layer } = e;
        if (layerType === 'polygon') {
          const coordinates = layer.getLatLngs();
          const transformed = {
            "rings": coordinates.map((ring) =>
              ring.map((coord) => [coord.lng,coord.lat])
            )
          };
          this.setState({
            setCoords:JSON.stringify(transformed['rings']),
            toggleBg:'toggleInactive',
          })
        }
        document.querySelector("#root > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-left > div.leaflet-draw.leaflet-control > div:nth-child(1)").style="display:None;";
      }

      sendRequest(){
        this.setState({fetchingStatus:"fetching"})
        const token = this.state.token;      
        // this.setState({fetchingStatus:"fetching",maxPrice:9999999999,minPrice:0})
        let url = `${this.state.serverIp}/api/getHousingPoints`
        if (window.location.protocol !== "file:"){
          fetch(url,{
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
              'Cache-Control':'no-cache',
              'Referrer':'https://casadata.io/',
              // Include the token in the Authorization header
              'Authorization': `Bearer ${token}`,
            },
            redirect: "follow", // manual, *follow, error
            // referrerPolicy:"no-referrer-when-downgrade",
            body: JSON.stringify(this.state.setCoords), // body data type must match "Content-Type"
          })
          .then(res=>res.json())
          .then(res=>{
            try{
              let data = res;
              if(this.state.filterOutliers===true){
                // First, extract all the SALESAMT values
                let salesPriceRegister = data['features'].map(x => x["attributes"]["SALESAMT"]);

                // Sort the sales prices
                let sortedSales = [...salesPriceRegister].filter(Boolean).sort((a, b) => a - b); // Filter out any null or undefined values

                // Calculate the first and third quartiles
                let q1 = sortedSales[Math.floor(sortedSales.length * 0.10)];
                let q3 = sortedSales[Math.floor(sortedSales.length * 0.90)];

                // Calculate the Interquartile Range (IQR)
                let iqr = q3 - q1;

                // Define the lower and upper bounds outside of which are outliers
                let lowerBound = Math.abs(q3 - (1.5 * iqr));
                let upperBound = q3 + (1.5 * iqr);
                data['features'].forEach(x => {
                    let saleAmt = x["attributes"]["SALESAMT"];
                    if (
                        saleAmt != null && 
                        saleAmt > this.state.minPrice && 
                        saleAmt < this.state.maxPrice && 
                        saleAmt >= lowerBound && 
                        saleAmt <= upperBound
                    ) {
                        this.setState(prevState => ({ dataRegister: [...prevState.dataRegister, x] }));
                    }
                });
              }
              else {
                data['features'].map(x => {
                    if (
                        x["attributes"]["SALESAMT"] !== null && 
                        x["attributes"]["SALESAMT"] > this.state.minPrice && 
                        x["attributes"]["SALESAMT"] < this.state.maxPrice
                    ) {
                        this.setState(prevState => ({ dataRegister: [...prevState.dataRegister, x] }));
                    }
                    return "";
                });
              }
            }
            catch{
              try{
                this.setState({pageIndex:'upgradePromotion',remainingCoolDown:res['cooldown']})

              }
              catch{
                if(res.ServerResponse.includes('Token')){
                  window.location.reload();
                }
              }
            }
          })
          .then(()=>{
            // this.setState({setCoords:"",fetchingStatus:"",maxPrice:9999999999,minPrice:0})
            this.setState({setCoords:"",fetchingStatus:""})
            document.querySelector("#root > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-left > div.leaflet-draw.leaflet-control > div:nth-child(1)").style="";
          }  
          )
          .catch((e)=>{
            this.setState({fetchingStatus:'',errorActive:'active',pageIndex:''})
          })
        }      
      }

      turnOnPlusMaxMode(){
        this.setState(prevState => ({
          decodeToken: {
              ...prevState.decodeToken,
              delta: "11A5D7A0C" // Replace "newDeltaValue" with your desired value
          }
        }));
      }



      sendTrialRequest(){
        this.setState({fetchingStatus:"fetching"})
        const token = this.state.token;      
        // this.setState({fetchingStatus:"fetching",maxPrice:9999999999,minPrice:0})
        let url = `${this.state.serverIp}/api/getTrialHousingPoints`
        if (window.location.protocol !== "file:"){
          fetch(url,{
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
              'Cache-Control':'no-cache',
              'Referrer':'https://casadata.io/',
              // Include the token in the Authorization header
              'Authorization': `Bearer ${token}`,
            },
            redirect: "follow", // manual, *follow, error
            // referrerPolicy:"no-referrer-when-downgrade",
            body: JSON.stringify(this.state.setCoords), // body data type must match "Content-Type"
          })
          .then(res=>res.json())
          .then(res=>{
            console.log(res)
            try{
              let data = res;
              if(this.state.filterOutliers===true){
                // First, extract all the SALESAMT values
                let salesPriceRegister = data['features'].map(x => x["attributes"]["SALESAMT"]);

                // Sort the sales prices
                let sortedSales = [...salesPriceRegister].filter(Boolean).sort((a, b) => a - b); // Filter out any null or undefined values

                // Calculate the first and third quartiles
                let q1 = sortedSales[Math.floor(sortedSales.length * 0.10)];
                let q3 = sortedSales[Math.floor(sortedSales.length * 0.90)];

                // Calculate the Interquartile Range (IQR)
                let iqr = q3 - q1;

                // Define the lower and upper bounds outside of which are outliers
                let lowerBound = Math.abs(q3 - (1.5 * iqr));
                let upperBound = q3 + (1.5 * iqr);
                data['features'].forEach(x => {
                    let saleAmt = x["attributes"]["SALESAMT"];
                    if (
                        saleAmt != null && 
                        saleAmt > this.state.minPrice && 
                        saleAmt < this.state.maxPrice && 
                        saleAmt >= lowerBound && 
                        saleAmt <= upperBound
                    ) {
                        this.setState(prevState => ({ dataRegister: [...prevState.dataRegister, x] }));
                    }
                });
              }
              else {
                data['features'].map(x => {
                    if (
                        x["attributes"]["SALESAMT"] !== null && 
                        x["attributes"]["SALESAMT"] > this.state.minPrice && 
                        x["attributes"]["SALESAMT"] < this.state.maxPrice
                    ) {
                        this.setState(prevState => ({ dataRegister: [...prevState.dataRegister, x] }));
                    }
                    return "";
                });
              }
            }
            catch{
              try{
                this.setState({pageIndex:'upgradePromotion',remainingCoolDown:res['cooldown']})

              }
              catch{
                if(res.ServerResponse.includes('Token')){
                  window.location.reload();
                }
              }
            }
          })
          .then(()=>{
            // this.setState({setCoords:"",fetchingStatus:"",maxPrice:9999999999,minPrice:0})
            this.setState({setCoords:"",fetchingStatus:""})
            this.turnOnPlusMaxMode()
            this.fetchTrials()
            document.querySelector("#root > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-left > div.leaflet-draw.leaflet-control > div:nth-child(1)").style="";
          }  
          )
          .catch((e)=>{
            this.setState({fetchingStatus:'',errorActive:'active',pageIndex:''})
          })
        }      
      }

      searchBtnIndexer(){
        if(this.state.trials!==0){
          return(<button onClick={
            ()=>{
              this.sendTrialRequest()
              sendAnalytics("HOMEPAGE","PlusMAX Trial Search",String(new Date()))
            }
          } className='searchBottom'>{this.languageIndexer("Busqueda Plus MAX",'PlusMAX Search')}</button>)
        }
        else{
          return(<div className='baseSearchBtn'>Buscar / Search</div>)
        }
      }

      togglePluxMaxTemp(){
        if(this.state.decodeToken.delta!=='37485923'||this.state.trials===0){
          return(
            <button onClick={()=>this.sendRequest()} className='fetchData'>
              <div className='baseSearchBtn'>Buscar / Search</div>
            </button>
          );
        }
        else{
          return(
            <div className='baseSearchField'>
              {this.searchBtnIndexer()}
            </div>
          )
        }
      }

      toggleSearchBtn(){
        if(this.state.setCoords!==""&this.state.fetchingStatus===''){
          return(
            <div className='sendBoxSmall' id="theSendBox">
              {this.togglePluxMaxTemp()}
              <div className='outliersCheckBox'><input checked={this.state.filterOutliers} onChange={()=>{
                if(this.state.filterOutliers===true){this.setState({filterOutliers:false})}
                else{this.setState({filterOutliers:true})}
              }} type="checkbox"></input>Filtrar Outliers</div>
            </div>
          );
        }
        
        else if(this.state.fetchingStatus==='fetching'){
          return(
            <div className='sendBoxSmall' id="theSendBox">
              <Audio
                height="40"
                width="80"
                radius="9"
                color="rgb(255, 166, 0)"
                ariaLabel="loading"
                wrapperStyle
                wrapperClass
              />
              <div>Buscando...</div>
            </div>
          );
      }
    }

      toggleTools = () => {
        if (this.state.zoomApropriate===true&&this.state.decodeToken!=="") {
          try{document.querySelector('#root > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-left > div.leaflet-draw.leaflet-control').style=""}catch{}
        }
        else{
          try{document.querySelector('#root > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-left > div.leaflet-draw.leaflet-control').style="display:none;"}catch{}
        }      
      }
      

      renderNumericLimits(){
        if(this.state.dataRegister.length>0 && this.state.sendBoxLimitsHide===false && this.checkIfUserIsPremium()){
          return(
            <div 
              onMouseDown={()=>this.holdTheMapStill()}
              onTouchStart={()=>this.holdTheMapStill()}
              onMouseUp={()=>this.releaseTheMap()}
              onTouchEnd={()=>this.releaseTheMap()}
            className='sendBox sendBoxLimits' id="theSendBox">
              {this.getMinMaxDateRange()}
              <input 
                type="number" 
                placeholder='Max Price/Precio Maximo' 
                className='maxPrice' 
                id="maxno"
                onChange={()=>{
                  if(document.getElementById("maxno").value!==""){
                    this.setState({maxPrice:document.getElementById('maxno').value})
                  }
                  else{
                    this.setState({maxPrice:999999999})
                  }
                }}>
              </input>
      
              <input 
                type="number" 
                placeholder='Min Price/Precio Minimo' 
                className='minPrice'
                id="minno"
                onChange={()=>{
                  if(document.getElementById("minno").value!==""){
                    this.setState({minPrice:document.getElementById('minno').value})
                  }
                  else{
                    this.setState({minPrice:0})
                  }
                }}>
              </input>

              <input 
                type="number" 
                placeholder='Estructura max(SQFT)' 
                className='maxPrice'
                id="maxStruct"
                onChange={()=>{
                  if(document.getElementById("maxStruct").value!==""){
                    this.setState({maxStruct:document.getElementById('maxStruct').value*10})
                  }
                  else{
                    this.setState({maxStruct:999999999})
                  }
                }}>
              </input>

              <input 
                type="number" 
                placeholder='Estructura min(SQFT)' 
                className='minPrice'
                id="minStruct"
                onChange={()=>{
                  if(document.getElementById("minStruct").value!==""){
                    this.setState({minStruct:document.getElementById('minStruct').value*10})
                  }
                  else{
                    this.setState({minStruct:0})
                  }
                }}>
              </input>

              <div className='dateClassifier'>Desde Fecha</div>
              <DatePicker className='datePicker' selectsStart selected={this.state.startDateToDisplay} onChange={(date)=>this.setState({startDate:date.getTime(),startDateToDisplay:date})} />
              <div className='dateClassifier'>Hasta Fecha</div>
              <DatePicker className='datePicker' selectsEnd selected={this.state.endDateToDisplay} onChange={(date)=>this.setState({endDate:date.getTime(),endDateToDisplay:date})} />
              <button onClick={()=>this.setState({sendBoxLimitsHide:true})} className='sendBoxMinimize'>Minimize</button>
            </div>);}
            else if(this.state.dataRegister.length>0 && this.state.sendBoxLimitsHide===true && this.checkIfUserIsPremium()){
              return(
                <div onClick={()=>this.setState({sendBoxLimitsHide:false})} className='sendBox sendBoxLimits sendBoxMinimizeDims filterBox' id="theSendBox">
                  Filtrar
                </div>
              );
              }
            }

            renderNumericLimitsRepo(){
              if(this.state.repoData.length>0 && this.checkIfUserIsPremium() && this.state.toggleBg==='toggleActive'){
                return(
                  <div 
                    onMouseDown={()=>this.holdTheMapStill()}
                    onTouchStart={()=>this.holdTheMapStill()}
                    onMouseUp={()=>this.releaseTheMap()}
                    onTouchEnd={()=>this.releaseTheMap()}
                  className='sendBox sendBoxLimits sendBoxLimitsRepo' id="theSendBox">
                    {this.getMinMaxDateRangeRepo()}
                    
                    <div className='dateClassifier'>Desde Fecha</div>
                    <DatePicker className='datePicker makeSmaller' selectsStart selected={this.state.startDateToDisplayRepo} onChange={(date)=>this.setState({startDateRepo:date.getTime(),startDateToDisplayRepo:date})} />
                    <div className='dateClassifier'>Hasta Fecha</div>
                    <DatePicker className='datePicker makeSmaller' selectsEnd selected={this.state.endDateToDisplayRepo} onChange={(date)=>this.setState({endDateRepo:date.getTime(),endDateToDisplayRepo:date})} />
                  </div>);}
                  }

      handleDeleteShape=()=>{
      this.decodeToken()
      this.fetchTrials()
      this.setState({
                  setCoords:'',
                  dataRegister:[],
                  startDate:"",
                  endDate:"",
                  maxPrice:999999999,
                  minPrice:0,
                  minStruct:0,
                  maxStruct:999999999,
                    })
      document.querySelector("#root > div > div > div.leaflet-control-container > div.leaflet-top.leaflet-left > div.leaflet-draw.leaflet-control > div:nth-child(1)").style="";
      }   

      flyToLocation(coords) {
        try{this.mapRef.current.flyTo([coords.y,coords.x],17.5)}catch{}
      } 

      checkIfUserIsPremium(){
        if(this.state.decodeToken.delta==='11A5D7A0C'||this.state.decodeToken.delta==='497CC0000'){return true}
        else{return false}
      }

      toggleThumbRepo(){
        if(this.state.toggleBg==='toggleInactive'){
          this.setState({toggleBg:'toggleActive'})
          try{this.consumeTrials()}catch{}
          this.fetchTrials()
          if(this.state.repoData.length===0){this.getRepoData()}
        }
        else{
          this.decodeToken()
          this.fetchTrials()
          this.setState({
            toggleBg:'toggleInactive',
            startDateRepo:'',
            endDateRepo: '',
            startDateToDisplayRepo:'',
            endDateToDisplayRepo:'',
          })
        }
      }

      getRepoData(){
        let url = `${this.state.serverIp}/api/getRepoPoints`;
        const token = this.state.token;
        if (window.location.protocol !== "file:"){
          fetch(url,{
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
              'Cache-Control':'no-cache',
              'Referrer':'https://casadata.io/',
              // Include the token in the Authorization header
              'Authorization': `Bearer ${token}`,
            },
            redirect: "follow", // manual, *follow, error
            // referrerPolicy:"no-referrer-when-downgrade",
          })
          .then(res=>res.json())
          .then(res=>{
            console.log(res)
            this.setState({repoData:res['response']})
          })
          .catch((err)=>{
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');
            window.location.reload();
          })  
      }}

      showRepoToggle(){
        if(this.state.decodeToken.delta==='11A5D7A0C'){
          return(
            <div className='repoToggle'>
              <div>
                {this.languageIndexer("Ver Reposeidas",'See Repossessed')}
              </div>
              <div onClick={()=>{
                if(this.state.trials===0){this.decodeToken()}
                this.toggleThumbRepo()
                // this.fetchTrials()
                // this.consumeTrials()
                }} className={`toggleBtn ${this.state.toggleBg}`}>
                <div className='toggleThumb'></div>
              </div>
            </div>
          );
        }
        else{
          return(
            <div className='repoToggle'>
              <div>{this.languageIndexer("Ver Reposeidas",'See Repossessed')}</div>
              <div onClick={()=>{
                //PAGE,ACTION,DATE
                sendAnalytics("MAP","PLUSMAX PROMPT BY FREE REPOTOGGLE",String(new Date()))
                // this.routeToPlusMax()
                if(this.state.trials===0){this.setState({pageIndex:'plusMaxPrompt'})}
                else{
                  this.turnOnPlusMaxMode()
                  this.toggleThumbRepo()
                  sendAnalytics("HOMEPAGE","PlusMAX Trial Repo Search",String(new Date()))
                }
                }} className={`toggleBtn ${this.state.toggleBg}`}>
                <div className='toggleThumb'></div>
              </div>
            </div>
          );
        }
      }

      languageSelector(){
        if(this.state.selectedLanguage==='spanish'){
          return(
            <div className="language-selector2">
                  <button
                      className={this.state.selectedLanguage === 'english' ? 'active' : ''}
                      onClick={()=>this.setState({selectedLanguage:'english',searchPlaceholder:'Search for address here'})}
                  >
                  <div className='languageFix2'>English</div><img src={usaflag} height='20px' width='auto' alt=''/>
                  </button>
            </div>
          );
        }
        else{
          return(
            <div className="language-selector2">
                  <button
                      className={this.state.selectedLanguage === 'spanish' ? 'active' : ''}
                      onClick={()=>this.setState({selectedLanguage:'spanish',searchPlaceholder:'Buscar Direccion Aquí'})}
                  >
                  <div className='languageFix2'>Español</div><img src={prflag} height='20px' width='auto' alt=''/>
                  </button>
            </div>
          );
        }
      }

      routeToPlusMax(){
        if(this.state.toggleBg==='toggleInactive'){
          this.setState({toggleBg:'toggleActive'})
          setTimeout(()=>this.setState({pageIndex:'account',subscriptionIndex:'PlusMAX'}),1000)
        }
        else{
          this.setState({
            toggleBg:'toggleInactive',
            startDateRepo:'',
            endDateRepo: '',
            startDateToDisplayRepo:'',
            endDateToDisplayRepo:'',
          })
        }
      }

      decideIfRenderTrials(){
        if(this.state.trials>0){
          return(
            <div className='membershipBoxLightExtended'>
              <div style={{display:'flex'}}>
                <strong>
                {this.languageIndexer("Membresia:",'Membership:')}&nbsp;
                </strong>
                  <div style={{color:'white'}} className='baselogo'>BASE</div>
              </div>
              <div className='plusMaxTemp'>
              {this.languageIndexer("Quedan",'')} <span style={{fontWeight:700}}>{this.state.trials}</span> {this.languageIndexer("busquedas PlusMAX ",'PlusMAX Searches Remaining')}
              </div>
              {this.showRepoToggle()}
              <div style={{fontWeight:700}}>
                {this.languageIndexer("Upgrade A",'Upgrade To')}
              </div>
              <div className='levelShow3' onClick={()=>{
                //PAGE,ACTION,DATE
                sendAnalytics("MAP","ROUTE TO PLUS MAX BY TAB UNDER TOGGLE",String(new Date()))
                this.setState({pageIndex:'account',subscriptionIndex:'PlusMAX'})
                }} style={{background:'#e1b12c',padding:3,color:'black',fontWeight:700,borderRadius:'5px'}}>PlusMAX</div>
              {/* <div className='levelShow3' onClick={()=>{
                //PAGE,ACTION,DATE
                sendAnalytics("MAP","ROUTE TO PLUS BY TAB UNDER TOGGLE",String(new Date()))
                this.setState({pageIndex:'account',subscriptionIndex:'Plus'})
                }} style={{background:'#273c75',padding:3,color:'white',fontWeight:700,borderRadius:'5px'}}>Plus</div> */}
                {this.languageSelector()}
            </div>
          );
        }
        else{
          return(
            <div className='membershipBoxLight'>
              <div style={{display:'flex'}}>
                <strong>
                {this.languageIndexer("Membresia:",'Membership:')}&nbsp;
                </strong>
                  <div style={{color:'white'}} className='baselogo'>BASE</div>
              </div>
              {this.showRepoToggle()}
              <div style={{fontWeight:700}}>
                {this.languageIndexer("Upgrade A",'Upgrade To')}
              </div>
              <div className='levelShow3' onClick={()=>{
                //PAGE,ACTION,DATE
                sendAnalytics("MAP","ROUTE TO PLUS MAX BY TAB UNDER TOGGLE",String(new Date()))
                this.setState({pageIndex:'account',subscriptionIndex:'PlusMAX'})
                }} style={{background:'#e1b12c',padding:3,color:'black',fontWeight:700,borderRadius:'5px'}}>PlusMAX</div>
              {/* <div className='levelShow3' onClick={()=>{
                //PAGE,ACTION,DATE
                sendAnalytics("MAP","ROUTE TO PLUS BY TAB UNDER TOGGLE",String(new Date()))
                this.setState({pageIndex:'account',subscriptionIndex:'Plus'})
                }} style={{background:'#273c75',padding:3,color:'white',fontWeight:700,borderRadius:'5px'}}>Plus</div> */}
                {this.languageSelector()}
            </div>
          );
        }
      }

      renderMemberBox(){
        if(this.state.decodeToken.delta==='11A5D7A0C'){
          return(
          <div className='membershipBox'>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <strong>
                {this.languageIndexer("Membresia:",'Membership:')}
              </strong>
              <div className='levelShow' style={{background:'#e1b12c',padding:3,color:'black',fontWeight:700,borderRadius:'5px'}}>PlusMAX</div>
            </div>
            {this.showRepoToggle()}
            {this.languageSelector()}
          </div>
          );
        }
        else if(this.state.decodeToken.delta==='497CC0000'){
          return(
          <div className='membershipBox'>
            <div style={{
              display:'flex',
              justifyContent:'center',
              alignItems:'center'
            }}><strong>Membresia:&nbsp;</strong><div className='levelShow' style={{
              background:'#273c75',
              padding:3,
              color:'white',
              fontWeight:700,
              borderRadius:'5px'
              }}>Plus</div>
              </div>
              {this.showRepoToggle()}
              <div style={{fontWeight:700}}>Hacer Upgrade a</div>
              <div className='levelShow3' onClick={()=>{
                this.setState({pageIndex:'account',subscriptionIndex:'PlusMAX'})
                //PAGE,ACTION,DATE
                sendAnalytics("MAP","PLUS MAX ROUTED BY TAB UNDER TOGGLE",String(new Date()))
                }} style={{background:'#e1b12c',padding:3,color:'black',fontWeight:700,borderRadius:'5px'}}>PlusMAX</div>
          </div>
          );
        }
        else if(this.state.decodeToken.delta==='37485923'){
          return(
            this.decideIfRenderTrials()
          );
        }
      }

      displayOrHideStats(){
        if(this.state.dataRegister.length>0&&this.checkIfUserIsPremium()&&this.state.decodeToken.delta==='11A5D7A0C'){
          return(
            <div 
            onMouseDown={()=>this.holdTheMapStill()}
            onTouchStart={()=>this.holdTheMapStill()}
            onMouseUp={()=>this.releaseTheMap()}
            onTouchEnd={()=>this.releaseTheMap()}
            >
              <StatsArea selectedLanguage={this.state.selectedLanguage} dataRegister={this.getFilteredData()}/>
            </div>
          );
        }
        else if(this.state.dataRegister.length>0&&this.checkIfUserIsPremium()===false){
          return(
            <div 
            onMouseDown={()=>this.holdTheMapStill()}
            onTouchStart={()=>this.holdTheMapStill()}
            onMouseUp={()=>this.releaseTheMap()}
            onTouchEnd={()=>this.releaseTheMap()}
            >
              <StatsAreaLight
              selectedLanguage={this.state.selectedLanguage}
              routeToPlus={()=>this.setState({pageIndex:'account',subscriptionIndex:'Plus'})} 
              routeToPlusMax={()=>this.setState({pageIndex:'account',subscriptionIndex:'PlusMAX'})}
              dataRegister={this.getFilteredData()}/>
            </div>
          );
        }
        else if(this.state.dataRegister.length>0&&this.state.decodeToken.delta==='497CC0000'){
          return(
            <div 
            onMouseDown={()=>this.holdTheMapStill()}
            onTouchStart={()=>this.holdTheMapStill()}
            onMouseUp={()=>this.releaseTheMap()}
            onTouchEnd={()=>this.releaseTheMap()}
            >
              <StatsAreaMid dataRegister={this.getFilteredData()}/>
            </div>
          );
        }
      }

      holdTheMapStill(){
        this.mapRef.current.dragging.disable()
      }

      releaseTheMap(){
        this.mapRef.current.dragging.enable()
      }

      getFilteredData() {
        return this.state.dataRegister.filter((data) => {
          let attributes = data.attributes || {};
      
          let indexedDate = moment(Math.abs(attributes.SALESDTTM)).format('YYYY-MM-DD');
          let indexedSalePrice = attributes.SALESAMT;
          let formatedDate = new Date(indexedDate);
          let indexedStruct = attributes.STRUCTURE;
          // Ensure none of the values are NaN or undefined
          if (typeof indexedSalePrice === 'undefined' || isNaN(indexedSalePrice)) return false;
          if (isNaN(formatedDate.getTime())) return false;
          if (typeof indexedStruct === 'undefined' || isNaN(indexedStruct)) return false;
      
          return indexedSalePrice >= this.state.minPrice 
              && indexedSalePrice <= this.state.maxPrice 
              && formatedDate.getTime() >= this.state.startDate 
              && formatedDate.getTime() <= this.state.endDate
              && indexedStruct >= this.state.minStruct
              && indexedStruct <= this.state.maxStruct;
        });
      }

      
      renderLoginBtns(){
        if(this.state.loggedIn===false){
          return(
            <Frontpage
            selectedLanguage={this.state.selectedLanguage}
            spanishSelected={()=>this.setState({selectedLanguage:'spanish',searchPlaceholder:'Buscar Direccion Aquí'})}
            englishSelected={()=>this.setState({selectedLanguage:'english',searchPlaceholder:'Search for address here'})}
            holdTheMapStill={()=>this.holdTheMapStill()}
            loginWindow={()=>this.setState({pageIndex:'login'})}
            signupWindow={()=>this.setState({pageIndex:'signup'})}
            termsWindowShow={()=>this.setState({pageIndex:'terms'})}
            contactWindowShow={()=>this.setState({pageIndex:'contact'})}
            policyWindowShow={()=>this.setState({pageIndex:'privacy'})}
            releaseTheMap={()=>this.releaseTheMap()}
            paidTierSelected={()=>this.setState({paidTierAtInit:true})}
            userSelectedDaily={()=>this.setState({selectedPayment:'daily'})}
            userSelectedMonthly={()=>this.setState({selectedPayment:'monthly'})}
            userSelectedWeekly={()=>this.setState({selectedPayment:'weekly'})}
            userSelectedAnnual={()=>this.setState({selectedPayment:'annual'})}
            />
          );
        }
      }

      renderLoginPages(){
        if(this.state.pageIndex==='login'){
          return(
          <div
            onMouseDown={()=>this.holdTheMapStill()}
            onTouchStart={()=>this.holdTheMapStill()}
            onMouseUp={()=>this.releaseTheMap()}
            onTouchEnd={()=>this.releaseTheMap()}
          >
            <LoginPage 
            fingerprint={this.state.fingerprint}
            selectedLanguage={this.state.selectedLanguage}
            serverIp={this.state.serverIp}
            userIsLoggedIn={()=>{
              this.userIsLogedIn()
              this.fetchTutorialPrompt()
              this.fetchTrials()
            }}
            closeLoginWindow={()=>this.setState({pageIndex:''})}
            switchToForgotPw={()=>this.setState({pageIndex:'forgot'})}
            userFirstLogin={()=>this.fetchTutorialPrompt()}
            />
          </div>);
        }
        else if(this.state.pageIndex==='signup'){
          return(
            <div
              onMouseDown={()=>this.holdTheMapStill()}
              onTouchStart={()=>this.holdTheMapStill()}
              onMouseUp={()=>this.releaseTheMap()}
              onTouchEnd={()=>this.releaseTheMap()}
            >
              <SignUp 
              userIsLoggedIn={()=>{
                this.userIsLogedIn()
                this.fetchTrials()
              }}
              selectedLanguage={this.state.selectedLanguage}
              serverIp={this.state.serverIp}
              switchToLoginWindow={()=>this.switchToLoginWindow()} 
              routeToConditions={()=>this.setState({pageIndex:'terms'})}
              userFirstLogin={()=>{
                if(!this.state.paidTierAtInit)
                  {this.fetchTutorialPrompt()}
              }}
              closeLoginWindow={()=>this.setState({
                pageIndex:'',
                paidTierAtInit:false
                })}/>
              
            </div>);
        }
        else if(this.state.pageIndex==='forgot'){
          return(<div
          onMouseDown={()=>this.holdTheMapStill()}
          onTouchStart={()=>this.holdTheMapStill()}
          onMouseUp={()=>this.releaseTheMap()}
          onTouchEnd={()=>this.releaseTheMap()}>
            <ForgotPassword
            selectedLanguage={this.state.selectedLanguage}
            closeLoginWindow={()=>this.setState({pageIndex:''})}
            />
          </div>);
        }
      }
      
      switchToLoginWindow(){
        this.setState({pageIndex:'login'})
      }
      

      logOutUser(){
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        window.location.reload();
      }
      
      logOutBtn(){
        if(this.state.loggedIn===true){
          return(
            <section className='accountControl'>
              <div style={{bottom:190,width:70}} onClick={()=>{
                //PAGE,ACTION,DATE
                sendAnalytics("MAP","ROUTE TO PR STATS",String(new Date()))
                this.setState({pageIndex:"stats"})
                }} className='logOutBtn'>
              <strong>
                {this.languageIndexer("Estadísticas Puerto Rico",'Puerto Rico Statistics')}
              </strong>
              </div>
              <div style={{bottom:145,width:70}} onClick={()=>{
                //PAGE,ACTION,DATE
                sendAnalytics("MAP","ROUTE TO ACCOUNT",String(new Date()))
                this.setState({pageIndex:"account"})
                }} className='logOutBtn'>
              <strong>
                {this.languageIndexer("Mi Cuenta",'My Account')}
              </strong>
              </div>
              <div style={{bottom:100,width:70}} onClick={()=>{
                //PAGE,ACTION,DATE
                sendAnalytics("MAP","ROUTE TO TUTORIAL",String(new Date()))
                this.setState({pageIndex:"tutorial"})
                }} className='logOutBtn'>
              <strong>Tutorial</strong>
              </div>
              <div style={{bottom:55,width:70}} onClick={()=>{
                //PAGE,ACTION,DATE
                sendAnalytics("MAP","LOG OUT",String(new Date()))
                this.logOutUser()
                }} className='logOutBtn'>
              <strong>Log Out</strong>
              </div>
            </section>
          );
        }
      }

      accountPage(){
        if(this.state.pageIndex==="account"){
          return(
          <AccountPage
          selectedLanguage={this.state.selectedLanguage}
          closeAccountWindow={()=>this.setState({pageIndex:'',toggleBg:'toggleInactive',subscriptionIndex:''})}
          holdTheMapStill={()=>this.holdTheMapStill()}
          releaseTheMap={()=>this.releaseTheMap()}
          serverIp={this.state.serverIp}
          subscriptionIndex={this.state.subscriptionIndex}
          userRejectedUpgrade={()=>this.setState({paidTierAtInit:false})}
          selectedPayment={this.state.selectedPayment}
          />
          );
        }
        else if(this.state.pageIndex==="tutorial"){
          return(
          <Tutorial
          selectedLanguage={this.state.selectedLanguage}
          closeTutorialWindow={()=>this.setState({pageIndex:''})}
          holdTheMapStill={()=>this.holdTheMapStill()}
          releaseTheMap={()=>this.releaseTheMap()}
          />
          );
        }
        else if(this.state.pageIndex==="stats"){
          return(
          <Stats
          selectedLanguage={this.state.selectedLanguage}
          closeTutorialWindow={()=>this.setState({pageIndex:''})}
          holdTheMapStill={()=>this.holdTheMapStill()}
          releaseTheMap={()=>this.releaseTheMap()}
          />
          );
        }
        else if(this.state.pageIndex==="upgradePromotion"){
          return(<Promo
          selectedLanguage={this.state.selectedLanguage}
          closePromoWindow={()=>this.setState({pageIndex:''})}
          coolDownStart={this.state.remainingCoolDown}
          closeTutorialWindow={()=>this.setState({pageIndex:''})}
          holdTheMapStill={()=>this.holdTheMapStill()}
          releaseTheMap={()=>this.releaseTheMap()}
          upgradeWindowPlus={()=>this.upgradeWindowPlus()}
          upgradeWindowMax={()=>this.upgradeWindowMax()}
          />);
        }
      }

      upgradeWindowPlus(){
        this.setState({pageIndex:'account',subscriptionIndex:'Plus'})
      }

      upgradeWindowMax(){
        this.setState({pageIndex:'account',subscriptionIndex:'PlusMAX'})
      }

      showExtraPointData(avgPriceSQFT,data){
        if(this.checkIfUserIsPremium()&&this.state.decodeToken.delta==='11A5D7A0C'){
          return(
            <div>
              <div><strong>{this.languageIndexer("Comprador",'Buyer')}:</ strong> {data.attributes.CONTACT}</div>
              <div><strong>{this.languageIndexer("Vendedor",'Seller')}:</strong> {data.attributes.SELLERNAME}</div>
              <div><strong>{this.languageIndexer("Valor intrinseco estimado",'Estimated Instrinsic Value')}:</strong> {this.formatCurrency((avgPriceSQFT*data.attributes.STRUCTURE/10))}</div>
              <div><strong>{this.languageIndexer("Estimado de Equidad",'Equity Estimate')}:</strong> {this.formatCurrency(((avgPriceSQFT*data.attributes.STRUCTURE/10)-data.attributes.SALESAMT))}</div>
              <div><strong>{this.languageIndexer("Mensualidad CRIM estimada",'Monthly Property Tax Estimate')}  $</strong> {this.formatCurrency((((data.attributes.TAXABLE*taxRates[data.attributes.MUNICIPIO])/12)*.9))}</div>
              <div className='floodIndicator'>{this.state.floodZoneWarning}</div>
            </div>
          );
        }
        else if(this.checkIfUserIsPremium()&&this.state.decodeToken.delta==='497CC0000'){
          return(
            <div>
              <div><strong>{this.languageIndexer("Valor intrinseco estimado",'Estimated Instrinsic Value')}:</strong> ${(avgPriceSQFT*data.attributes.STRUCTURE/10).toFixed(2)}</div>
              <div><strong>{this.languageIndexer("Estimado de Equidad",'Equity Estimate')}:</strong> ${((avgPriceSQFT*data.attributes.STRUCTURE/10).toFixed(2)-data.attributes.SALESAMT).toFixed(2)}</div>
              <div style={{display:'flex'}}><strong>{this.languageIndexer("Comprador",'Buyer')}:&nbsp;</ strong> {this.languageIndexer("Disponible con memebresia",'Available with')} <div className='levelShow2' style={{background:'#e1b12c',padding:3,color:'black',fontWeight:700,borderRadius:'5px'}}>PlusMAX</div></div>
              <div style={{display:'flex'}}><strong>{this.languageIndexer("Vendedor",'Seller')}:&nbsp;</ strong> {this.languageIndexer("Disponible con memebresia",'Available with')} <div className='levelShow2' style={{background:'#e1b12c',padding:3,color:'black',fontWeight:700,borderRadius:'5px'}}>PlusMAX</div></div>
            </div>
          );
        }
        else{
          return(
            <div>
              <div style={{display:'flex'}}><strong>{this.languageIndexer("Comprador",'Buyer')}:&nbsp;</ strong> {this.languageIndexer("Disponible con memebresia",'Available with')} <div className='levelShow2' style={{background:'#e1b12c',padding:3,color:'black',fontWeight:700,borderRadius:'5px'}}>PlusMAX</div></div>
              <div style={{display:'flex'}}><strong>{this.languageIndexer("Vendedor",'Seller')}:&nbsp;</ strong> {this.languageIndexer("Disponible con memebresia",'Available with')} <div className='levelShow2' style={{background:'#e1b12c',padding:3,color:'black',fontWeight:700,borderRadius:'5px'}}>PlusMAX</div></div>
              <div style={{display:'flex'}}><strong>{this.languageIndexer("Valor intrinseco estimado",'Estimated Instrinsic Value')}:</strong> {this.languageIndexer("Disponible con memebresia",'Available with')} <div className='levelShow2' style={{background:'#e1b12c',padding:3,color:'black',fontWeight:700,borderRadius:'5px'}}>PlusMAX</div></div>
              <div style={{display:'flex'}}><strong>{this.languageIndexer("Estimado de Equidad",'Equity Estimate')}:</strong> {this.languageIndexer("Disponible con memebresia",'Available with')} <div className='levelShow2' style={{background:'#e1b12c',padding:3,color:'black',fontWeight:700,borderRadius:'5px'}}>PlusMAX</div></div>
            </div>
          );
        }
      }

      // handleMouseOver(event){
      //   try{
      //   if(this.state.decodeToken.delta==='11A5D7A0C'){
      //     this.setState({floodZoneWarning:''})
      //     let coords=event.target.getLatLng()
      //     fetch(`https://gis.jp.pr.gov/2013_RecibidorGeoComentarios/SometerGeoComentario/pnt_to_loc82_2022?ident=fromH_mipr2022&input_X=${coords.lng}&input_Y=${coords.lat}&isr=4326&osr=32161&method=`)
      //     .then(res=>res.json())
      //     .then(data=>{
      //       if(data.dato.atributos.Inun_Zona!=="X"){
      //         this.setState({floodZoneWarning:'ZONA INUNDABLE'})
      //       }
      //       else{
      //         this.setState({floodZoneWarning:''})
      //       }
      //   })}
      //   }catch{}
      // }


      handleMouseOver(event){
        try{
        if(this.state.decodeToken.delta==='11A5D7A0C'){
          this.setState({floodZoneWarning:''})
          let coords=event.target.getLatLng()
          fetch(`https://gis.jp.pr.gov/2013_RecibidorGeoComentarios/SometerGeoComentario/pnt_to_loc82?input_X=${coords.lng}&input_Y=${coords.lat}&isr=4326&osr=32161&method=`)
          .then(res=>res.json())
          .then(data=>{
            if(data.results[0].attributes.Inun_Zona!=="X"){
              this.setState({floodZoneWarning:'ZONA INUNDABLE'})
            }
            else{
              this.setState({floodZoneWarning:''})
            }
        })}
        }catch{}
      }

      renderSearchBar(){
        if(this.state.decodeToken!==""){
          return(<Searchbar 
            selectedLanguage={this.state.selectedLanguage}
            searchPlaceholder={this.state.searchPlaceholder}
            holdTheMapStill={()=>this.holdTheMapStill()}
            releaseTheMap={()=>this.releaseTheMap()}
            sendDataToParent={data=>this.flyToLocation(data)}/>);
        }
      }

      showRepoPoints() {
        this.state.repoData.map((data, index) => {
        // Check if data or data.attributes is undefined and skip the iteration if so
        if (!data || !data.attributes) {
          return null;
        }

        const redIcon = new L.Icon({
          iconUrl: exclamationIcon,
          iconSize: [40, 40],
        });

        let indexedDate = moment(Math.abs(data.attributes.SALESDTTM)).format('YYYY-MM-DD');
        
        return (
          <Marker
            position={[data.attributes.INSIDE_Y, data.attributes.INSIDE_X]}
            icon={redIcon}
          >
            <Tooltip className='cloudstyle'>
              <div><strong># Catastro:</strong> {data.attributes.CATASTRO}</div>
              <div><strong>Principal reposeido:</strong> ${data.attributes.SALESAMT}</div>
              <div><strong>Fecha:</strong> {indexedDate}</div>
              <div><strong>Tamaño de estructura:</strong> {data.attributes.STRUCTURE / 10} FT²</div>
              <div><strong>Terreno Total:</strong> {data.attributes.CABIDA} M²</div>
              <div><strong>Municipio:</strong>{data.attributes.MUNICIPIO}</div>
              <div style={{ overflowWrap: "break-word", wordWrap: "break-word" }}><strong>Direccion:</strong>{this.formatDireccion(data.attributes.DIRECCION_FISICA)}</div>
              <div><strong>Reposeido por:</strong> {data.attributes.CONTACT}</div>
              <div><strong>Reposeido de:</strong> {data.attributes.SELLERNAME}</div>
            </Tooltip>
          </Marker>
        );
      });
    }

      termsWindow(){
        if(this.state.pageIndex==='terms'){
          return(
            <Terms
            holdTheMapStill={()=>this.holdTheMapStill()}
            releaseTheMap={()=>this.releaseTheMap()}
            closeTerms={()=>this.setState({pageIndex:''})}/>
          );
        }
        else if(this.state.pageIndex==='contact'){
          return(
            <section
            onTouchStart={()=>this.holdTheMapStill()}
            onTouchEnd={()=>this.releaseTheMap()}
            closeTerms={()=>this.setState({pageIndex:''})}
            className='termsWindow'>
                <section className='contactInner' style={{maxWidth:700,}}>
                    <div className='closeHeader'>
                        <button style={{marginTop:10}} onClick={()=>this.setState({pageIndex:''})}>Cerrar</button>
                    </div>
                    <div className='contactStyling'>
                        Para consultas contactanos escribiendo al email <strong>information@casadata.io</strong> con gusto le asistiremos!
                    </div>
                </section>
            </section>
          );
        }
        else if(this.state.pageIndex==='privacy'){
          return(
            <Privacy
            holdTheMapStill={()=>this.holdTheMapStill()}
            releaseTheMap={()=>this.releaseTheMap()}
            closeTerms={()=>this.setState({pageIndex:''})}
            />
          );
        }
      }

      fetchTutorialPrompt(){
        let url=`${this.state.serverIp}/api/tutorialprompt`;
        let token = '';
        if(!localStorage.getItem('token')){
          token = sessionStorage.getItem('token')
        }
        else{
          token = localStorage.getItem('token')
        }
        fetch(url,{
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control':'no-cache',
            'Referrer':'https://casadata.io/',
            // Include the token in the Authorization header
            'Authorization': `Bearer ${token}`,
          },
          redirect: "follow", // manual, *follow, error
          // referrerPolicy:"no-referrer-when-downgrade",
        })
        .then(res=>res.json())
        .then(res=>{
          this.setState({userFirstLogin:res.tutorialprompt})
        })
      }

      fetchTrials(){
        let url=`${this.state.serverIp}/api/trialRegister`;
        let token = '';
        if(!localStorage.getItem('token')){
          token = sessionStorage.getItem('token')
        }
        else{
          token = localStorage.getItem('token')
        }
        fetch(url,{
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json',
            'Cache-Control':'no-cache',
            'Referrer':'https://casadata.io/',
            // Include the token in the Authorization header
            'Authorization': `Bearer ${token}`,
          },
          redirect: "follow", // manual, *follow, error
          // referrerPolicy:"no-referrer-when-downgrade",
        })
        .then(res=>res.json())
        .then(res=>{
          this.setState({trials:res.freesearches})
        })
      }

      renderFirstLogin(){
        if(this.state.userFirstLogin===false){
          return(<Prompt
            trials = {this.state.trials}
            selectedLanguage={this.state.selectedLanguage}
            holdTheMapStill={()=>this.holdTheMapStill()}
            releaseTheMap={()=>this.releaseTheMap()}
            closePrompt={()=>this.fetchTutorialPrompt()}
            goToTutotial={()=>{
              this.setState({pageIndex:"tutorial"})
              this.fetchTutorialPrompt()
            }}
          />)
        }
      }

      formatCurrency(amount) {
        // Convert the number to a string with fixed two decimal points
        let formattedAmount = amount.toFixed(2);
        
        // Use regular expression to insert commas in the right places
        formattedAmount = formattedAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
        // Add the dollar sign at the beginning
        return `$${formattedAmount}`;
    }

      decideIfRenderVenta(salesamt){
        if(this.state.decodeToken.delta==='11A5D7A0C'){
          return(<div>&nbsp;{this.formatCurrency(salesamt)}</div>);
        }
        else{
          return(<div style={{display:'flex'}}>&nbsp;{this.languageIndexer("Disponible con memebresia",'Available with')} <div className='levelShow2' style={{background:'#e1b12c',padding:3,color:'black',fontWeight:700,borderRadius:'5px'}}>PlusMAX</div></div>);
        }
      }

      renderPlusMaxPrompt(){
        if(this.state.pageIndex==='plusMaxPrompt'){
          return(<PlusMaxPrompt
            selectedLanguage={this.state.selectedLanguage}
            holdTheMapStill={()=>this.holdTheMapStill()}
            releaseTheMap={()=>this.releaseTheMap()}
            closePlusMaxPrompt={()=>this.setState({pageIndex:''})}
            trials={this.state.trials}
            
            routeToPlusMax={()=>{
              this.setState({pageIndex:''})
              this.routeToPlusMax()
            }}
          />)
        }
      }

      consumeTrials(){
        const token = this.state.token;      
        // this.setState({fetchingStatus:"fetching",maxPrice:9999999999,minPrice:0})
        let url = `${this.state.serverIp}/api/consumeTrials`
        if (window.location.protocol !== "file:"){
          fetch(url,{
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json',
              'Cache-Control':'no-cache',
              'Referrer':'https://casadata.io/',
              // Include the token in the Authorization header
              'Authorization': `Bearer ${token}`,
            },
            redirect: "follow", // manual, *follow, error
            // referrerPolicy:"no-referrer-when-downgrade",
            // body: JSON.stringify(""), // body data type must match "Content-Type"
          })
          .then(res=>res.json())
          .then(data=>console.log(data))
      }}

      languageIndexer(spanishMessage,englishMessage){
        if(this.state.selectedLanguage==='spanish'){return(spanishMessage);}
        else if(this.state.selectedLanguage==='english'){return(englishMessage);}
      }

      translateTransaction(transaction){
        if(transaction==='VENTA'){
          return('VENTA/SALE');
        }
        else if(transaction==='REPOSEIDA'){
          return('REPOSEIDA/REPOSSESSED');
        }
        else if(transaction==='REFINANCIAMIENTO'){
          return('REFINANCIAMIENTO/REFI');
        }
      }

      render() {
        return (
          <div className="App">
            {this.handleInformaticError()}
            <MapContainer
              ref={this.mapRef}
              className='mapstyle'
              center={this.state.initialCoords} 
              zoom={this.state.initialZoomOut} 
              style={{height: "100%", width: "100%"}}
            >
                <TileLayer
                    url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                    maxZoom= {20}
                    subdomains={['mt1','mt2','mt3']}
                />
                {this.renderSearchBar()}
                <FeatureGroup>
                  <EditControl
                    position='topleft'
                    onCreated={this._onCreate}
                    onDeleted={()=>this.handleDeleteShape()} 
                    draw={{
                      rectangle: false,
                      circle:false,
                      marker:false,
                      circlemarker: false,
                      polyline:false,
                    }}
                  />
                  {this.toggleSearchBtn()}
                </FeatureGroup>
                {this.renderNumericLimits()}
                {this.state.dataRegister.map((data, index) => {
                  const redIcon = new L.Icon({
                    iconUrl: exclamationIcon,
                    iconSize: [40, 40],
                  });
                  const refiCon = new L.Icon({
                    iconUrl: reficon,
                    iconSize: [40, 40],
                  });
                  const color = this.getSalesAmtColor(data.attributes.SALESAMT);
                  let indexedDate = moment(Math.abs(data.attributes.SALESDTTM)).format('YYYY-MM-DD');
                  let indexedSalePrice = data.attributes.SALESAMT;
                  let formatedDate = new Date(moment(Math.abs(data.attributes.SALESDTTM)).format('YYYY-MM-DD'));
                  let transaction = transactionType(data.attributes);
                  let indexedStruct = data.attributes.STRUCTURE; 
                  if(
                    indexedSalePrice>=this.state.minPrice &&
                    indexedSalePrice<=this.state.maxPrice &&
                    formatedDate.getTime()>=this.state.startDate&& 
                    formatedDate.getTime()<=this.state.endDate&&
                    indexedStruct>=this.state.minStruct&&
                    indexedStruct<=this.state.maxStruct
                    ){
                    if(transaction==="VENTA"){
                      let maxSalesDTTMIndex = 0;
                      let secondMaxSalesDTTMIndex = 0;
                      let maxSalesDTTM = Number.NEGATIVE_INFINITY;
                      let secondMaxSalesDTTM = Number.NEGATIVE_INFINITY;

                      for (let i = 0; i < this.state.dataRegister.length; i++) {
                          const currentSalesDTTM = this.state.dataRegister[i].attributes.SALESDTTM;
                          if (currentSalesDTTM > maxSalesDTTM) {
                              // Move current max to second max
                              secondMaxSalesDTTM = maxSalesDTTM;
                              secondMaxSalesDTTMIndex = maxSalesDTTMIndex;

                              // Update max
                              maxSalesDTTM = currentSalesDTTM;
                              maxSalesDTTMIndex = i;
                          } else if (currentSalesDTTM > secondMaxSalesDTTM && currentSalesDTTM !== maxSalesDTTM) {
                              secondMaxSalesDTTM = currentSalesDTTM;
                              secondMaxSalesDTTMIndex = i;
                          }
                      }
                      let lastElement = this.state.dataRegister[maxSalesDTTMIndex];
                      if(transactionType(lastElement.attributes)!=='VENTA'){
                        lastElement = this.state.dataRegister[secondMaxSalesDTTMIndex];
                      }
                      let avgPriceSQFT=lastElement['attributes']['SALESAMT']/(lastElement['attributes']['STRUCTURE']/10)
                      return (
                        <CircleMarker 
                          key={index}
                          center={[data.attributes.INSIDE_Y, data.attributes.INSIDE_X]}
                          pathOptions={{ color: color, fillColor: color }}
                          fillOpacity={0.9}
                          stroke={false}
                          radius={window.innerWidth < 600 ? 10 : 7}
                          eventHandlers={{
                            mouseover:this.handleMouseOver
                          }}
                        >
                          <Tooltip className='cloudstyle'>
                            <div><strong># Catastro:</strong> {data.attributes.CATASTRO}</div>
                            <div style={{display:'flex'}}><strong>{this.languageIndexer("Precio de Venta",'Sales Price')}:</strong> {this.decideIfRenderVenta(data.attributes.SALESAMT)}</div>
                            <div><strong>{this.languageIndexer("Fecha",'Date')}:</strong> {indexedDate}</div>
                            <div><strong>{this.languageIndexer("Municipio",'Municipality')}:</strong>{data.attributes.MUNICIPIO}</div>
                            <div style={{overflowWrap: "break-word", wordWrap: "break-word",width:100}}><strong>{this.languageIndexer("Direccion",'Address')}:</strong>{this.formatDireccion(data.attributes.DIRECCION_FISICA)}</div>
                            <div><strong>
                              {this.languageIndexer("Tamaño de estructura:",'Structure Size')}
                            </strong> {data.attributes.STRUCTURE/10} FT²</div>
                            <div><strong>{this.languageIndexer("Terreno Total",'Total Land')}:</strong> {data.attributes.CABIDA} M²</div>
                            <div><strong>{this.languageIndexer("Transaccion",'Transaction')}:</strong> {this.translateTransaction(transaction)}</div>
                            {this.showExtraPointData(avgPriceSQFT,data)}
                          </Tooltip>
                        </CircleMarker>
                      );
                    }
                    else if(transaction==="REPOSEIDA"&&this.state.decodeToken.delta==='11A5D7A0C'){
                      let maxSalesDTTMIndex = 0;
                      let secondMaxSalesDTTMIndex = 0;
                      let maxSalesDTTM = Number.NEGATIVE_INFINITY;
                      let secondMaxSalesDTTM = Number.NEGATIVE_INFINITY;

                      for (let i = 0; i < this.state.dataRegister.length; i++) {
                          const currentSalesDTTM = this.state.dataRegister[i].attributes.SALESDTTM;
                          if (currentSalesDTTM > maxSalesDTTM) {
                              // Move current max to second max
                              secondMaxSalesDTTM = maxSalesDTTM;
                              secondMaxSalesDTTMIndex = maxSalesDTTMIndex;

                              // Update max
                              maxSalesDTTM = currentSalesDTTM;
                              maxSalesDTTMIndex = i;
                          } else if (currentSalesDTTM > secondMaxSalesDTTM && currentSalesDTTM !== maxSalesDTTM) {
                              secondMaxSalesDTTM = currentSalesDTTM;
                              secondMaxSalesDTTMIndex = i;
                          }
                      }
                      let lastElement = this.state.dataRegister[maxSalesDTTMIndex];
                      if(transactionType(lastElement.attributes)!=='VENTA'){
                        lastElement = this.state.dataRegister[secondMaxSalesDTTMIndex];
                      }
                      let avgPriceSQFT=lastElement['attributes']['SALESAMT']/(lastElement['attributes']['STRUCTURE']/10)
                      return(
                        <Marker
                        position={[data.attributes.INSIDE_Y, data.attributes.INSIDE_X]}
                        icon = {redIcon}
                        >
                          <Tooltip className='cloudstyle'>
                              <div><strong># Catastro:</strong> {data.attributes.CATASTRO}</div>
                              <div><strong>Principal reposeido:</strong> ${data.attributes.SALESAMT}</div>
                              <div><strong>Fecha:</strong> {indexedDate}</div>
                              <div><strong>Tamaño de estructura:</strong> {data.attributes.STRUCTURE/10} FT²</div>
                              <div><strong>Terreno Total:</strong> {data.attributes.CABIDA} M²</div>
                              <div><strong>Municipio:</strong>{data.attributes.MUNICIPIO}</div>
                              <div style={{overflowWrap: "break-word", wordWrap: "break-word"}}><strong>Direccion:</strong>{this.formatDireccion(data.attributes.DIRECCION_FISICA)}</div>
                              <div><strong>Reposeido por:</strong> {data.attributes.CONTACT}</div>
                              <div><strong>Reposeido de:</strong> {data.attributes.SELLERNAME}</div>
                              <div><strong>Transaccion:</strong> {this.translateTransaction(transaction)}</div>
                              <div><strong>{this.languageIndexer("Valor intrinseco estimado",'Estimated Instrinsic Value')}:</strong> ${this.formatCurrency((avgPriceSQFT*data.attributes.STRUCTURE/10))}</div>
                              <div><strong>{this.languageIndexer("Estimado de Equidad",'Equity Estimate')}:</strong> ${((avgPriceSQFT*data.attributes.STRUCTURE/10).toFixed(2)-data.attributes.SALESAMT).toFixed(2)}</div>
                            </Tooltip>
                        </Marker>
                      );
                    }
                    else if(transaction!=="REPOSEIDA"&&transaction!=="VENTA"&&this.state.decodeToken.delta==='11A5D7A0C'){
                      return(
                        <Marker
                        position={[data.attributes.INSIDE_Y, data.attributes.INSIDE_X]}
                        icon = {refiCon}
                        >
                          <Tooltip className='cloudstyle'>
                              <div><strong>Principal refinanciado:</strong> ${data.attributes.SALESAMT}</div>
                              <div><strong>Fecha:</strong> {indexedDate}</div>
                              <div><strong>Municipio:</strong>{data.attributes.MUNICIPIO}</div>
                              <div style={{overflowWrap: "break-word", wordWrap: "break-word"}}><strong>Direccion:</strong>{this.formatDireccion(data.attributes.DIRECCION_FISICA)}</div>
                              <div><strong>Refinanciado por:</strong> {data.attributes.SELLERNAME}</div>
                              <div><strong>Transaccion:</strong> {this.translateTransaction(transaction)}</div>
                            </Tooltip>
                        </Marker>
                      );
                    }
                  }
                  return ("");                
                })}
            {this.renderNumericLimitsRepo()}
            {this.state.repoData.map((data, index) => {
                // Check if data or data.attributes is undefined and skip the iteration if so
                if (!data || !data.attributes || this.state.toggleBg==='toggleInactive' || transactionType(data.attributes)!=="REPOSEIDA") {
                  return null;
                }

        
                let indexedDate = moment(Math.abs(data.attributes.SALESDTTM)).format('YYYY-MM-DD');
                let formatedDate = new Date(moment(Math.abs(data.attributes.SALESDTTM)).format('YYYY-MM-DD'));
                if(
                  formatedDate.getTime()>=this.state.startDateRepo&& 
                  formatedDate.getTime()<=this.state.endDateRepo
                  ){
                return (
                  <CircleMarker 
                      key={index}
                      center={[data.attributes.INSIDE_Y, data.attributes.INSIDE_X]}
                      pathOptions={{ fillColor: '#c23616' }}
                      fillOpacity={0.9}
                      stroke={false}
                      radius={7}
                    >
                    <Tooltip className='cloudstyle'>
                      <div><strong># Catastro:</strong> {data.attributes.CATASTRO}</div>
                      <div><strong>Principal reposeido:</strong> ${data.attributes.SALESAMT}</div>
                      <div><strong>Fecha:</strong> {indexedDate}</div>
                      <div><strong>Municipio:</strong>{data.attributes.MUNICIPIO}</div>
                      <div style={{ overflowWrap: "break-word", wordWrap: "break-word" }}><strong>Direccion:</strong>{this.formatDireccion(data.attributes.DIRECCION_FISICA)}</div>
                      <div><strong>Reposeido por:</strong> {data.attributes.CONTACT}</div>
                      <div><strong>Reposeido de:</strong> {data.attributes.SELLERNAME}</div>
                    </Tooltip>
                  </CircleMarker>
                );}
              return("");})
            }
            {this.displayOrHideStats()}
            {this.renderLoginBtns()}
            {this.renderLoginPages()}
            {this.logOutBtn()}
            {this.accountPage()}
            {this.renderMemberBox()}
            {this.termsWindow()}
            {this.renderFirstLogin()}
            {this.renderPlusMaxPrompt()}
            </MapContainer>
          </div>
        );
      }

    }
    export default App;

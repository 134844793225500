import React from 'react';
import { Component } from 'react';
import './Frontpage.css';
import Logo from '../../images/CASADATA.png';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import sendAnalytics from '../../Functions/analytics';
import paymentConfig from '../../Functions/paymentVariables';
import individual from '../../images/individual_smiling.jpg';
import realtor_lady from '../../images/realtor_lady.jpg';
import appraiser from '../../images/appraiser.jpg';
import searchicon from '../../images/searchicon.png';
import bluechart from '../../images/bluechart.png';
import filtersmall from '../../images/filtersmall.png';
import repohome from '../../images/repohome.png';
import moneybag from '../../images/moneybag.png';
import prflag from './icons8-puerto-rico-48.png';
import usaflag from './icons8-usa-flag-48.png';
import cursor from './icons8-hand-cursor-80.png';
class Frontpage extends Component{
    constructor(props){
        super(props);
        this.state={
            showFirstContent:true,
            showSecondContent:true,
            windowTooSmallAdjustClass:'',
            lowerAdjustClass:'',
            infoWindowIndex:'terms',
            textIndex:'default',
            videoDarkenClass:'videoDarken',
            indexedSavings:'',
            isIos:/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
            plusMaxPrice:[paymentConfig.plusMaxDaily,' 2 días (days)'],
            defaultRadio:true,
            privateSelectorClass:'motivationalSelected',
            realtorSelectorClass:'motivationalSelector',
            appraiserSelectorClass:'motivationalSelector',
            individualShowOrHide:'',
            realtorShowOrHide:'hideDefault',
            appraiserShowOrHide:'hideDefault',
            graphData:[
                        {
                            name: '11/01/2023',
                            uv: 100000,
                            pv: 2400,
                            amt: 2400,
                        },
                        {
                            name: '11/01/2023',
                            uv: 200000,
                            pv: 1398,
                            amt: 2210,
                        },
                        {
                            name: '11/01/2023',
                            uv: 300000,
                            pv: 9800,
                            amt: 2290,
                        },
                        {
                            name: '11/01/2023',
                            uv: 400000,
                            pv: 3908,
                            amt: 2000,
                        },
                        {
                            name: '12/01/2023',
                            uv: 500000,
                            pv: 4800,
                            amt: 2181,
                        },
                        {
                            name: '01/01/2024',
                            uv: 600000,
                            pv: 3800,
                            amt: 2500,
                        },
                        {
                            name: '02/01/2024',
                            uv: 400000,
                            pv: 4300,
                            amt: 2100,
                        },
              ]
        };
    }

    componentDidMount() {
        this.mainContainer = document.getElementById("mainContainer");
        if (this.mainContainer) {
            this.mainContainer.addEventListener('scroll', this.handleScroll);
        }
        window.addEventListener('resize',()=>this.adjustWindowSize())
        this.adjustWindowSize()
        window.gtag('event','page_view',{'page_location':'Front Page'})
        if(this.state.isIos){
            this.setState({videoDarkenClass:'videoLighten'})
        }
        this.props.userSelectedDaily()
        try{document.querySelector('#movie_player > div.ytp-chrome-top.ytp-show-cards-title').style='display:None;'}catch{}
    }

    componentDidUpdate(){
        try{document.querySelector('#movie_player > div.ytp-chrome-top.ytp-show-cards-title').style='display:None;'}catch{}
    }

    
    componentWillUnmount() {
        if (this.mainContainer) {
            this.mainContainer.removeEventListener('scroll', this.handleScroll);
        }
    }


    adjustWindowSize(){
        if(window.innerWidth<390){
            this.setState({windowTooSmallAdjustClass:'smallScreen',lowerAdjustClass:'smallScreenLow',scrollUpper:1670,lowerBtn:'lowerBtnSmall'})
        }
        else if(window.innerWidth>=390&&window.innerWidth<=579){
            this.setState({windowTooSmallAdjustClass:'midScreen',lowerAdjustClass:'midScreenLow',scrollUpper:1460,lowerBtn:'lowerBtnMid'})
        }
        else{
            this.setState({windowTooSmallAdjustClass:'largeScreen',lowerAdjustClass:'largeScreenLow',scrollUpper:1160,lowerBtn:'lowerBtnLarge'})
        }
    }

    scrollWindowSize(){
        if(window.innerWidth<390){
            document.querySelector('#mainContainer').scrollTo({
                top: 2250,
                behavior: 'smooth'
            });
        }
        else if(window.innerWidth>=390&&window.innerWidth<=579){
            document.querySelector('#mainContainer').scrollTo({
                top: 2100,
                behavior: 'smooth'
            });
        }
        else{
            document.querySelector('#mainContainer').scrollTo({
                top: 1700,
                behavior: 'smooth'
            });
        }
    }

    handleScroll = () => {
        if(this.mainContainer.scrollTop>=170){this.setState({showFirstContent:true})};
        if(this.mainContainer.scrollTop>=500){this.setState({showSecondContent:true})};
    }   


    showFirstContent(){
        if(this.state.showFirstContent===true){
            return( 
                    <div 
                    className='firstContentContainer'>
                        <div className='offerText'>
                            <div
                            style={{
                                color:"#FFA500",
                                fontSize:30,
                                fontFamily:'Roboto',
                                padding:10,
                                paddingLeft:20,
                                fontWeight:400
                            }}
                            >{this.languageIndexer(
                                "Asegura tu inversion con una decision informada.",
                                "Protect your investment by making an informed decision."
                                )}</div> 
                            <div className='accedeText'>
                            {this.languageIndexer(
                                "Accede a información sobre ventas de propiedades en todo Puerto Rico y sigue de cerca los cambios del mercado en tus áreas de interés.",
                                "Access information on property sales throughout Puerto Rico and closely follow market changes in your areas of interest."
                                )}
                            </div>
                        </div>
                        <div className='offersImage'>
                            <ResponsiveContainer width="100%" minHeight={200}>
                                <BarChart   data={this.state.graphData}>
                                <Bar dataKey="uv" fill="#FFA500" />
                                    <XAxis stroke="white" tick={{ fill: 'white' }} dataKey="name" />
                                    <YAxis tick={{ fill: 'white' }}/>         
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
            );
        }
    }

    showSecondContent(){
        if(this.state.showSecondContent===true){
            return( 
                    <div className='secondContentContainer'>
                        <section className='badgeContainers'>
                            <div className='badgeMain'>
                                <img className='showCaseIcon' src={searchicon} alt=''/>
                                <div className='badgeLetters'>
                                    <div style={{fontSize:17}}>
                                        {this.languageIndexer(
                                        "Accesa Los Datos",
                                        "Access The Data"
                                        )}
                                    </div>
                                    <div style={{width:150,fontSize:12,color:'rgba(255,255,255,.6)',lineHeight:2}}>
                                        {this.languageIndexer(
                                        "Con nuestra fuente historica informatica mas completa de propiedades en PR.",
                                        "With our most complete historical source of property sales in Puerto Rico."
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='badgeMain'>
                                <img className='showCaseIcon' src={bluechart} alt=''/>
                                <div className='badgeLetters'>
                                    <div style={{fontSize:17}}>
                                    {this.languageIndexer(
                                        "Analisis avanzado",
                                        "Advanced Analysis"
                                        )}
                                    </div>
                                    <div style={{width:150,fontSize:12,color:'rgba(255,255,255,.6)',lineHeight:2}}>
                                        {this.languageIndexer(
                                        "Excelente para determinar el valor de propiedades y detectar tendencias de mercado.",
                                        "Excellent for determining property values ​​and detecting market trends."
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='badgeMain'>
                                <img className='showCaseIcon' src={filtersmall} alt=''/>
                                <div className='badgeLetters'>
                                    <div style={{fontSize:17}}>
                                    {this.languageIndexer(
                                        "Filtros",
                                        "Filters"
                                        )}
                                    </div>
                                    <div style={{width:150,fontSize:12,color:'rgba(255,255,255,.6)',lineHeight:2}}>
                                    {this.languageIndexer(
                                        "Filtra por fechas, tamaño de propiedad y precios para precisar tus resultados.",
                                        "Filter by dates, property size and prices to refine your results."
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='badgeMain'>
                                <img className='showCaseIcon' src={repohome} alt=''/>
                                <div className='badgeLetters'>
                                    <div style={{fontSize:17}}>
                                        {this.languageIndexer(
                                        "Propiedades Reposeidas",
                                        "Repossessed Properties"
                                        )}
                                    </div>
                                    <div style={{width:150,fontSize:12,color:'rgba(255,255,255,.6)',lineHeight:2}}>
                                        {this.languageIndexer(
                                        "Encuentralas antes que salgan en los listados publicos bancarios.",
                                        "Find them before they appear in the open market listings."
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='badgeMain'>
                                <img className='showCaseIcon' src={moneybag} alt=''/>
                                <div className='badgeLetters'>
                                    <div style={{fontSize:17}}>
                                    {this.languageIndexer(
                                        "Estimado Mensualidad CRIM",
                                        "Monthly Property Tax Estimate"
                                        )}
                                    </div>
                                    <div style={{width:150,fontSize:12,color:'rgba(255,255,255,.6)',lineHeight:2}}>
                                        {this.languageIndexer(
                                        "Calculamos el estimado de pago CRIM para cada propiedad.",
                                        "We calculate the estimated monthly property tax (CRIM)."
                                        )}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className='offerText2'>
                             <div 
                             style={{
                                color:"#FFA500",
                                fontSize:30,
                                fontFamily:'Roboto',
                                padding:10,
                                paddingLeft:20,
                                fontWeight:400
                            }}
                             >
                                {this.languageIndexer(
                                        "El analisis de propiedades mas avanzado",
                                        "The most advanced property analysis resource."
                                        )}
                             </div>
                             <div className='accedeTextOther'>
                                {this.languageIndexer(
                                        "Evalúa si una propiedad tiene el precio adecuado antes de vender o comprar. Analiza inversiones y ahorra potenciales miles en tu próxima transacción.",
                                        "Evaluate whether a property is appropriately priced before selling or buying. Analyze investments and save potential thousands on your next transaction."
                                        )}
                             </div>
                        </div>
                        <div className='imagesGallery'>
                        {/* <ImageGallery
                        // showFullscreenButton={false}
                        autoPlay={true}
                        className="imageGallery"
                                items={images} /> */}
                        {this.languageVideoIndexer()}
                        <div className='button-container2'>
                            <div onClick={()=>{
                                this.scrollWindowSize()
                                sendAnalytics("FRONTPAGE","Ver planes pressed.",String(new Date()))
                            }} className={`modern-button2 ${this.state.lowerBtn}`}>
                                <div>
                                {this.languageIndexer(
                                        "VER OFERTAS",
                                        "SEE OFFERS"
                                        )}
                                </div>
                                <div style={{
                                    color:'rgba(255,255,255,1)',
                                    fontSize:13
                                    }}>{this.languageIndexer("PRESIONE AQUÍ","PRESS HERE")}</div>
                                <div><img className='cursor' src={cursor} alt=""/></div>
                            </div>
                            {/* <button className='modern-button'>Ver Planes</button> */}
                        </div>
                        </div>
                    </div>
            );
        }
    }

    // decideIfShow(){
    //     if(!this.state.isIos){
    //         return(
    //             <video autoPlay muted loop className='background-video'>
    //                 <source src={video} type="video/mp4" />
    //             </video>
    //         );
    //     }
    // }

    customerSwitcher(){
        if(this.state.privateSelectorClass==='motivationalSelected'){
        return(
            <div>
                <div className='firstRotatingText'>
                    <div style={{width:'70%'}}>{this.languageIndexer(
                        "Determinar el mas preciso valor de la propiedad que deseas comprar o vender.",
                        'To determine the most precise value of properties you wish to sell or buy.'
                        )}</div>
                </div>
                <div className='secondtRotatingText'>
                    <div style={{width:'60%'}}>
                        {this.languageIndexer(
                            "Hacer o aceptar ofertas en el mercado de bienes raices de Puerto Rico.",
                            "To submit or accept offers in Puerto Rico's real estate market."
                            )}
                    </div>
                </div>
                <div className='thirdRotatingText'>
                    <div style={{width:'65%'}}>
                        {this.languageIndexer(
                            "Detectar tendencias de mercado antes de invertir en propiedades.",
                            "To identify market tendencies before investing in properties."
                            )}
                    </div>
                </div>
            </div>
        )}
        else if(this.state.realtorSelectorClass==='motivationalSelected'){
            return(
                <div>
                    <div className='firstRotatingText'>
                        <div style={{width:'70%'}}>
                            {this.languageIndexer(
                            "Facilitar las transacciones entre tus vendedores y compradores.",
                            "Facilitate your clients transactions."
                            )}
                        </div>
                    </div>
                    <div className='secondtRotatingText'>
                        <div style={{width:'60%'}}>
                            {this.languageIndexer(
                            "Expandir tu inventario con referencias de propiedades reposeidas.",
                            "Expand your inventory with references of reposessed properties."
                            )}
                        </div>
                    </div>
                    <div className='thirdRotatingText'>
                        <div style={{width:'65%'}}>
                            {this.languageIndexer(
                            "Aconsejar tus clientes en las mas recientes condiciones de mercado.",
                            "Advise your clients on the most up to date property market conditions."
                            )}
                        </div>
                    </div>
                </div>
            )
        }
        else if(this.state.appraiserSelectorClass==='motivationalSelected'){
            return(
                <div>
                    <div className='firstRotatingText'>
                        <div style={{width:'70%'}}>{this.languageIndexer(
                            "Determinar el mas preciso valor de la propiedad que deseas analizar.",
                            'Determine the most precise value of properties you wish to analyze.'
                            )}</div>
                    </div>
                    <div className='secondtRotatingText'>
                        <div style={{width:'60%'}}>
                            {this.languageIndexer(
                            "Identificar modelos de propiedades para un area de interes.",
                            "Filter properties by price, structure size and transaction dates."
                            )}
                        </div>
                    </div>
                    <div className='thirdRotatingText'>
                        <div style={{width:'65%'}}>
                            {this.languageIndexer(
                            "Facilitar y expeditar procesos bancarios de bienes raices.",
                            "Facilitate and expedite real estate bank transactions."
                            )}
                        </div>
                    </div>
                </div>
            )
        }
    }

    textSwitcher(){
        if(this.state.textIndex==='default'){
            return(
                <div on className={`background-video-container ${this.state.videoDarkenClass}`}>
                        {/* {this.decideIfShow()} */}
                    <div className='content-overlay'>
                        <div className='motivationalText'>
                            <div className='motivationalTextInner'>
                                <div className='convinceText'>
                                    <div style={{display:'flex',justifyContent:'space-around'}}>
                                        <div style={{display:'flex',flexDirection:'column'}} onClick={()=>{
                                            this.setState({privateSelectorClass:'motivationalSelected',realtorSelectorClass:'motivationalSelector',appraiserSelectorClass:'motivationalSelector',realtorShowOrHide:'hideImage',individualShowOrHide:'showImage',appraiserShowOrHide:'hideImage'})
                                            sendAnalytics("FRONTPAGE", "Personal Use Pressed.", String(new Date()));
                                            }} className={`${this.state.privateSelectorClass}`}>
                                            <div className='usoText'>{this.languageIndexer("PARA ÉXITO PERSONAL",'FOR PERSONAL SUCCESS')}</div>
                                            <div className='presioneText'>{this.languageIndexer("PRESIONE AQUI",'PRESS HERE')}</div>
                                        </div>
                                        <div style={{display:'flex',flexDirection:'column'}} onClick={()=>{
                                            sendAnalytics("FRONTPAGE", "Realtor Use Pressed.", String(new Date()));
                                            this.setState({privateSelectorClass:'motivationalSelector',realtorSelectorClass:'motivationalSelected',appraiserSelectorClass:'motivationalSelector',realtorShowOrHide:'showImage',individualShowOrHide:'hideImage',appraiserShowOrHide:'hideImage'})
                                            }} className={`${this.state.realtorSelectorClass}`}>
                                            <div className='usoText'>{this.languageIndexer("REALTOR PROFESIONAL","PROFESSIONAL REALTORS")}</div>
                                            <div className='presioneText'>{this.languageIndexer("PRESIONE AQUI",'PRESS HERE')}</div>
                                        </div>
                                        <div style={{display:'flex',flexDirection:'column'}} onClick={()=>{
                                            sendAnalytics("FRONTPAGE", "Appraiser Use Pressed.", String(new Date()));
                                            this.setState({privateSelectorClass:'motivationalSelector',realtorSelectorClass:'motivationalSelector',appraiserSelectorClass:'motivationalSelected',realtorShowOrHide:'hideImage',individualShowOrHide:'hideImage',appraiserShowOrHide:'showImage'})
                                            }} className={`${this.state.appraiserSelectorClass}`}>
                                            <div className='usoText'>{this.languageIndexer("TASADOR PROFESIONAL","PROFESSIONAL APPRAISERS")}</div>
                                            <div className='presioneText'>{this.languageIndexer("PRESIONE AQUI",'PRESS HERE')}</div>
                                        </div>
                                    </div>
                                    <div className='convinceShow'>
                                        <div className='whatcanwedoquestion'>{this.languageIndexer("CASADATA te dara el apoyo","At CASADATA we support")} <br></br> {this.languageIndexer("de sentir confianza al","giving you the confidence")}</div>
                                        <img className={`individualImg ${this.state.individualShowOrHide}`} src={individual} alt=''/>
                                        <img className={`realtorImg ${this.state.realtorShowOrHide}`} src={realtor_lady} alt=''/>
                                        <img className={`appraiserImg ${this.state.appraiserShowOrHide}`} src={appraiser} alt=''/>
                                    </div>
                                    {this.customerSwitcher()}
                                    <div className='languageSelector'>
                                        <div className="language-selector">
                                            <button
                                                className={this.props.selectedLanguage === 'spanish' ? 'active' : ''}
                                                onClick={()=>this.props.spanishSelected()}
                                            >
                                            <div className='languageFix'>Español</div><img src={prflag} height='20px' width='auto' alt=''/>
                                            </button>
                                            <button
                                                className={this.props.selectedLanguage === 'english' ? 'active' : ''}
                                                onClick={()=>this.props.englishSelected()}
                                            >
                                            <div className='languageFix'>English</div><img src={usaflag} height='20px' width='auto' alt=''/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='button-container'>
                            <div onClick={() => {
                                document.querySelector('#mainContainer').scrollTo({
                                    top: this.state.scrollUpper,
                                    behavior: 'smooth'
                                });
                                sendAnalytics("FRONTPAGE", "Como funciona pressed.", String(new Date()))
                            }} className='modern-button'>
                                <div>{this.languageIndexer("CÓMO FUNCIONA?","HOW DOES IT WORK?")}</div>
                                <div style={{
                                    color:'rgba(255,255,255,1)',
                                    fontSize:13
                                    }}>{this.languageIndexer("PRESIONE AQUÍ","PRESS HERE")}</div>
                                {/* <div className='arrowIndicator'>
                                    <div className='arrow1'>
                                        <div className='arrowLine1'></div>
                                        <div className='arrowLine2'></div>
                                    </div>
                                    <div className='arrow2'>
                                        <div className='arrowLine3'></div>
                                        <div className='arrowLine4'></div>
                                    </div>
                                </div> */}
                                <div><img className='cursor' src={cursor} alt=""/></div>
                            </div>
                            {/* <button className='modern-button'>Ver Planes</button> */}
                        </div>
                    </div>
                </div>
            );
        }
        else{
            return(
                <div className='motivationalText'>
                    <div className='casaText'>CASADATA <len style={{color:'red'}}>PR</len></div>
                    <div className='aboutText'>
                    En <span style={{color:'red',fontWeight:700}}>CASADATA</span>, te ofrecemos acceso a la base de datos de ventas de propiedades en 
                    Puerto Rico más <span style={{color:'red',fontWeight:700}}>completa</span>, complementada con herramientas analíticas <span style={{color:'red',fontWeight:700}}>modernas.</span><br></br><br></br> Nuestro 
                    objetivo es que al comprar o vender, puedas tomar decisiones <span style={{color:'red',fontWeight:700}}>sólidas </span> 
                    e informadas, basadas en análisis de datos recientes.
                    <br></br>
                    Regístrate en CasaData y toma decisiones con <span style={{color:'red',fontWeight:700}}>confianza</span>.
                    </div>
                    <div className='button-container'>
                        {/* <button onClick={()=>{
                            document.querySelector('#mainContainer').scrollTo({
                                top: 925,
                                behavior: 'smooth'
                              });
                              sendAnalytics("FRONTPAGE","Como funciona pressed.",String(new Date()))
                        }} className='modern-button'>Como Funciona?</button> */}
                        {/* <button className='modern-button'>Ver Planes</button> */}
                    </div>
                </div>
            );
        }
    }

    languageIndexer(spanishMessage,englishMessage){
        if(this.props.selectedLanguage==='spanish'){
            return(spanishMessage);
        }
        else if(this.props.selectedLanguage==='english'){return(englishMessage);}
    }

    languageVideoIndexer(spanishMessage,englishMessage){
        if(this.props.selectedLanguage==='spanish'){
            return(
                <div class='embed-container'>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/xJjZ8fU-oTk?si=NkoRgi4Nl5oucxF5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            );
        }
        else if(this.props.selectedLanguage==='english'){
            return(
                <div class='embed-container'>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/mI_g0qG1WkE?si=ohftvfS85Dmy5Ofx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            );
        }
    }

    render(){
        const buttonStyle = {
            backgroundColor: 'rgb(213, 139, 0)', // Light green color
            color: '#fff',
            marginLeft: '10px',
            marginRight: '10px',
            width: '110px',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '14px',
            boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1), 0px 2px 4px -1px rgba(0,0,0,0.06)',
            border: 'none',
            transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
            textTransform: 'uppercase', // Material UI buttons often use uppercase text
            textAlign: 'center',
            borderRadius:200,
            fontWeight:700,
          };
      
          const buttonHoverStyle = {
            backgroundColor: 'rgb(161, 105, 0)', // Slightly darker green for hover
            boxShadow: '0px 6px 8px -2px rgba(0,0,0,0.2), 0px 4px 6px -2px rgba(0,0,0,0.1)',
          };
        return(
            <div 
            onMouseDown={()=>this.props.holdTheMapStill()}
            onTouchStart={()=>this.props.holdTheMapStill()}
            onMouseUp={()=>this.props.releaseTheMap()}
            onTouchEnd={()=>this.props.releaseTheMap()}
            className="frontPage"
            >
                <section className='frontPageContent'>
                    <div className='navBar'>
                        <div className='logoContainer2Outer'>
                            <div className='logoContainer2'>
                                <img className='navLogo' height={47} width={47} src={Logo} alt=""/>
                                <div className='logoName'>CASADATA</div>
                            </div>
                        </div>
                        <div className='navBarButtons'>
                        <div
                            onClick={() => {
                                this.props.loginWindow();
                                sendAnalytics("FRONTPAGE", "Routed to login.", String(new Date()));
                            }}
                            className='navBtn'
                            style={buttonStyle}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor;
                                e.currentTarget.style.boxShadow = buttonHoverStyle.boxShadow;
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor;
                                e.currentTarget.style.boxShadow = buttonStyle.boxShadow;
                            }}
                            >
                            Login
                            </div>
                            <div
                            onClick={() => {
                                this.props.signupWindow()
                                //PAGE,ACTION,DATE
                                sendAnalytics("FRONTPAGE","Routed to signup.",String(new Date()))
                            }}
                            className='navBtn'
                            style={buttonStyle}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor;
                                e.currentTarget.style.boxShadow = buttonHoverStyle.boxShadow;
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor;
                                e.currentTarget.style.boxShadow = buttonStyle.boxShadow;
                            }}
                            >
                            {this.languageIndexer("Registrate",'Signup')}
                            </div>
                            {/* <div
                            onClick={() => {
                                this.props.signupWindow()
                                //PAGE,ACTION,DATE
                                sendAnalytics("FRONTPAGE","Routed to signup.",String(new Date()))
                            }}
                            className='navBtn'
                            style={buttonStyle}
                            onMouseOver={(e) => {
                                e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor;
                                e.currentTarget.style.boxShadow = buttonHoverStyle.boxShadow;
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor;
                                e.currentTarget.style.boxShadow = buttonStyle.boxShadow;
                            }}
                            >
                            Quien Somos?
                            </div> */}
                        </div>
                    </div>
                    <div className='mainContent' id='mainContainer'>
                        {this.textSwitcher()}
                        <div className={`offersData ${this.state.windowTooSmallAdjustClass}`}>
                            {this.showFirstContent()}
                            {/* <div className='divLine'></div> */}
                            {this.showSecondContent()}
                            {/* <div className='divLine2'></div> */}
                            <div className={`finalContent`}>
                                <div 
                                className={`finalText ${this.state.lowerAdjustClass}`}
                                style={{
                                    color:"#FFA500",
                                    fontSize:30,
                                    fontFamily:'Roboto',
                                    padding:10,
                                    // paddingLeft:20,
                                    fontWeight:400,
                                    transform:'translateY(-30px)',
                                    // width:550,
                                    textAlign:'center'
                                }}
                                >
                                    {this.languageIndexer(
                                        "Crea tu cuenta para explorar nuestras diferentes soluciones de ultima generación.",
                                        "Create your account to explore our different next-generation solutions."
                                        )}
                                </div>
                                <div className='membershipShowcase'>
                                    <div style={{background:'#e1b12c'}} className='membershipCard membershipCardPlusMax'>
                                        <div className='membershipCardHeader'>
                                            <div>PlusMAX</div>
                                        </div>
                                        <div className='pricingContainer'>
                                            <div className='dollarAmmount'>
                                                <div style={{fontSize:25,marginTop:12}}>$</div>{this.state.plusMaxPrice[0]}
                                            </div>
                                            <div>/{this.state.plusMaxPrice[1]}</div>
                                        </div>
                                        <div className='indexedSavings'>{this.state.indexedSavings}</div>
                                        <div className='selectorStyle' style={{display:'flex',flexDirection:'column',alignItems:'flex-start',marginLeft:45}}>
                                            <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:5}}>
                                                <input onClick={()=>{
                                                    this.setState({plusMaxPrice:[paymentConfig.plusMaxDaily,' 2 días (days)','daily'],defaultRadio:true,indexedSavings:''})
                                                    this.props.userSelectedDaily()
                                            }} type="radio" checked={this.state.defaultRadio} name="plusmaxoptions"/><div><strong>PlusMAX</strong>
                                             {this.languageIndexer(" por 2 días."," for 2 days")}
                                             </div>
                                            </div>
                                            <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:5}}>
                                                <input onClick={()=>{
                                                    this.props.userSelectedWeekly()
                                                    this.setState({plusMaxPrice:[paymentConfig.plusMaxWeekly,' 7 días (days)','weekly'],defaultRadio:false,indexedSavings:`AHORRE ${(100-(((paymentConfig.plusMaxWeekly/7)/(paymentConfig.plusMaxDaily/2))*100)).toFixed(0)}% = $${(paymentConfig.plusMaxWeekly/7).toFixed(2)}/dia`})
                                                    }} type="radio" name="plusmaxoptions"/><div><strong>PlusMAX</strong>
                                                     {this.languageIndexer(" por 7 días."," for 7 days")}
                                                     </div>
                                            </div>
                                            <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:5}}>
                                                <input onClick={()=>{
                                                    this.props.userSelectedMonthly()
                                                    this.setState({plusMaxPrice:[paymentConfig.plusMaxMonthly,' Mensual (Monthly)','monthly'],defaultRadio:false,indexedSavings:`AHORRE ${(100-(((paymentConfig.plusMaxMonthly/30.5)/(paymentConfig.plusMaxDaily/2))*100)).toFixed(0)}% = $${(paymentConfig.plusMaxMonthly/30.5).toFixed(2)}/dia`})
                                                    }} type="radio" name="plusmaxoptions"/><div><strong>PlusMAX</strong> 
                                                    {this.languageIndexer(" pago mensual."," monthly.")}
                                                    </div>
                                            </div>
                                            <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:5}}>
                                                <input onClick={()=>{
                                                    this.props.userSelectedAnnual()
                                                    this.setState({plusMaxPrice:[paymentConfig.plusMaxYearly,' Anual (Annual)','annual'],defaultRadio:false,indexedSavings:`AHORRE ${(100-(((paymentConfig.plusMaxYearly/366)/(paymentConfig.plusMaxDaily/2))*100)).toFixed(0)}% = $${(paymentConfig.plusMaxYearly/366).toFixed(2)}/dia`})
                                                    }} type="radio" name="plusmaxoptions"/><div><strong>PlusMAX</strong>{this.languageIndexer(" pago anual."," yearly")}</div>
                                            </div>
                                        </div>
                                        <div className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer("Precios de Venta",'Sales Prices')}
                                        </div>
                                        <div className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer("Fechas de Venta",'Sales Dates')}
                                        </div>
                                        <div className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer("Tamaños de estructuras.",'Structure Sizes')}
                                        </div>
                                        <div className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer("Tamaños de terreno.",'Land Size')}
                                        </div>
                                        <div className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer("Busquedas ilimitadas!",'Unlimited Searches')}
                                        </div>
                                        <div className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer("Filtra por precio, tamaño y fecha de venta.",'Filter by price, size and sale date.')}
                                        </div>
                                        <div className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer("Estimado de valor / equidad de propiedad.",'Estimated property value/equity.')}
                                        </div>
                                        <div className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer("Herramientas de analisis estadistico.",'Statistical analysis tools.')}
                                        </div>
                                        <div className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer("Bajar / exportar datos.",'Download / Export data.')}
                                        </div>
                                        <div className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer("Ver comprador y vendedor.",'See names of buyers and sellers.')}
                                        </div>
                                        <div className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer("Marcador de reposeidas y re-financeadas.",'Repossessed and refinanced marker.')}
                                        </div>
                                        <div className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer("Indicador de Inundables.",'Flood Zone Alert!')}
                                        </div>
                                        <div className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer("Estimado Mensualidad CRIM.",'Property tax monthly estimate.')}
                                        </div>
                                        <div 
                                        style={{marginTop:5}}
                                        onClick={()=>{
                                            this.props.signupWindow()
                                            this.props.paidTierSelected()
                                            sendAnalytics("FRONTPAGE","Signup routed by plusmax.",String(new Date()))
                                        }}
                                        className='joinBtn'>
                                            {this.languageIndexer("SELECCIONAR!",'SELECT!')}
                                        </div>
                                    </div>
                                    {/* <div style={{background:'#273c75'}} className='membershipCard'>
                       
                                        <div className='membershipCardHeader'>
                                            <div>Plus</div>
                                            <div>
                                                <div className='priceText priceTextPlus'>5/Mes</div>
                                            </div>
                                        </div>
                                        <div className='membershipBullet'>
                                            <div className='whiteTab'></div><div style={{width:210,display:'flex',alignItems:'center',justifyContent:'center'}}>Busquedas ilimitadas!</div>
                                        </div>
                                        <div className='membershipBullet'>
                                            <div className='whiteTab'></div><div style={{width:210,display:'flex',alignItems:'center',justifyContent:'center'}}>Acceso extendido a ventas historicas de propiedades.</div>
                                        </div>
                                        <div className='membershipBullet'>
                                            <div className='whiteTab'></div><div style={{width:210,display:'flex',alignItems:'center',justifyContent:'center'}}>Filtra por precio, tamaño y fecha de venta.</div>
                                        </div>
                                        <div className='membershipBullet'>
                                            <div className='whiteTab'></div><div style={{width:210,display:'flex',alignItems:'center',justifyContent:'center'}}>Estimado de valor / equidad de propiedad.</div>
                                        </div>
                                        <div className='membershipBullet'>
                                            <div className='whiteTab'></div><div style={{width:210,display:'flex',alignItems:'center',justifyContent:'center'}}>Herramientas de analisis estadistico / informatico.</div>
                                        </div>
                                        <div className='membershipBullet'>
                                            <div className='whiteTab'></div><div style={{width:210,display:'flex',alignItems:'center',justifyContent:'center'}}>Bajar / exportar datos.</div>
                                        </div>
                                        <div 
                                        style={{marginTop:221}}
                                        onClick={()=>{
                                            this.props.signupWindow()
                                            //PAGE,ACTION,DATE
                                            sendAnalytics("FRONTPAGE","Signup routed by plus.",String(new Date()))
                                        }}
                                        className='joinBtn'>SELECCIONAR!</div>
                                    </div> */}
                                    <div style={{background:'#aaa69d'}} className='membershipCard membershipCardPlusMax'>
                                        <div className='membershipCardHeader'>
                                            <div>Base</div>
                                        </div>
                                        <div className='pricingContainer'>
                                            <div className='dollarAmmount'>
                                                <div style={{fontSize:25,marginTop:12}}>$</div>0
                                            </div>
                                        </div>
                                        <div className='indexedSavings'></div>
                                        <div className='selectorStyle' style={{height:95,display:'flex',flexDirection:'column',alignItems:'flex-start',marginLeft:45}}>
                                            {this.languageIndexer(
                                                "Desarrollado para que el usuario se familiarice con la plataforma y sus capacidades.",
                                                'Developed so that the user becomes familiar with the platform and its capabilities.'
                                            )}
                                        </div>
                                        <div style={{transform:'translateY(21px)'}} className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer(
                                                "Fechas de Venta",
                                                'Sales Dates'
                                            )}
                                        </div>
                                        <div style={{transform:'translateY(21px)'}} className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer(
                                                "Tamaños de estructuras.",
                                                'Structure Size'
                                            )}
                                        </div>
                                        <div style={{transform:'translateY(21px)'}} className='membershipBullet plusMaxChanges'>
                                            {this.languageIndexer(
                                                "Tamaños de terrenos.",
                                                'Land Size'
                                            )}
                                        </div>
                                        <div 
                                        style={{marginTop:346}}
                                        onClick={()=>{
                                            this.props.signupWindow()
                                            //PAGE,ACTION,DATE
                                            sendAnalytics("FRONTPAGE","Signup routed by base.",String(new Date()))
                                        }}
                                        className='joinBtn'>
                                            {this.languageIndexer(
                                                "SELECCIONAR!",
                                                'SELECT!'
                                            )}
                                        </div>
                                    </div>
                                    
                                </div>
                                <div style={{
                                    fontSize:14,
                                    color:'rgba(0,0,0,.6)',
                                    margin:20
                                }}>
                                    <div className='bottomNav'>
                                        <div onClick={()=>this.props.termsWindowShow()}>Terminos de Uso</div>
                                        <div onClick={()=>this.props.policyWindowShow()}>Politica de Privacidad</div>
                                        <div onClick={()=>this.props.contactWindowShow()}>Contactanos</div>
                                    </div>
                                <div style={{color:'white'}}>Copyright © CASADATA LLC 2024 All Rights Reserved</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }   
}

export default Frontpage;



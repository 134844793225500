import React,{Component} from 'react';
import './Stats.css';
import countplot from './images/monthly_counts.png';
import medio from './images/median.png';
class Stats extends Component{
    constructor(props){
        super();
        this.state={
            showIndex:'year',
            btnStyle1:'statsTitleActive',
            btnStyle2:'statsTitle'
        }
    }

    languageIndexer(spanishMessage,englishMessage){
        if(this.props.selectedLanguage==='spanish'){return(spanishMessage);}
        else if(this.props.selectedLanguage==='english'){return(englishMessage);}
    }

    chartIndexer(){
        if(this.state.showIndex==='year'){
            return(
            <img className='countplot' src={countplot} alt=''/>
            )
        }
        else if(this.state.showIndex==='median'){
            return(
            <div className='medianScroll'>
                <img className='median' src={medio} alt=''/>
            </div>
            )
        }
    }

    render(){
        return(
            <section
            onMouseDown={this.props.holdTheMapStill}
            onTouchStart={this.props.holdTheMapStill}
            onMouseUp={this.props.releaseTheMap}
            onTouchEnd={this.props.releaseTheMap}
            className='statsContainer'>
                <div className='accountHeader'>
                    <div>{this.languageIndexer("Estadísticas Puerto Rico",'Puerto Rico Statistics')}</div>
                    <div onClick={()=>this.props.closeTutorialWindow()} className='closeBtn'>
                        {this.languageIndexer("CERRAR",'CLOSE')}
                    </div>
                </div>
                <div className='statsContainerInner'>
                    <div className='statsIndex'>
                        <div onClick={()=>this.setState({showIndex:'year',btnStyle1:'statsTitleActive',btnStyle2:'statsTitle'})} className={this.state.btnStyle1}>{this.languageIndexer("VENTAS POR AÑO",'SALES BY YEAR')}</div>
                        <div onClick={()=>this.setState({showIndex:'median',btnStyle2:'statsTitleActive',btnStyle1:'statsTitle'})} className={this.state.btnStyle2}>{this.languageIndexer("PRECIO MEDIO",'MEDIAN PRICE')}</div>
                    </div>
                    {this.chartIndexer()}
                </div>
            </section>
        );
    }
}

export default Stats;